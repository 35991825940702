import { useTranslation } from "react-i18next"
import { BoxTitle, BoxWithShadow, DynamicTable, MainBtn } from "../../../Components"
import { FC } from "react"
import { SalaryIncreaseTableCols } from "../../../Assets/Data/Dashboard/ProfileMangement/Salary"

type SalaryIncreaseTableProps = {
    handleShow: (name: string) => void,
    salaryIncreases: any,
    loading: boolean
}
const SalaryIncreaseTable: FC<SalaryIncreaseTableProps> = ({ handleShow, salaryIncreases, loading }) => {
    const { t } = useTranslation()
    return (
        <BoxWithShadow additionalClass="mb-3 users">
            <div className="flex-between-start">
                <BoxTitle title={t("SalaryIncrease")} />
                <MainBtn title={t("add")} onClick={() => handleShow("addSalaryIncreases")} />
            </div>
            <DynamicTable
                loading={loading}
                dataSource={salaryIncreases}
                columns={SalaryIncreaseTableCols(t, handleShow).cols}
                pagination={false}
            />
        </BoxWithShadow>
    )
}

export default SalaryIncreaseTable