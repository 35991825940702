import { useTranslation } from "react-i18next"
import { InputsArray } from "../../../Assets/Data/Dashboard/LeavesSuggestion"
import schema from "../../../Utils/Validations/LeavesSuggestion/schema"
import { FormikContainer } from "../../Forms"
import { FormikHelpers, FormikValues } from "formik"
import useQueryPost from "../../../Custom Hooks/useQueryPost"
import LeavesSuggestionService from "../../../Services/LeavesSuggestionService"
import { FC } from "react"

const initialValues = {
    start_date: "",
    end_date: "",
}

type AddModalProps = {
    handleClose: () => void
}
const AddModal: FC<AddModalProps> = ({ handleClose }) => {
    const { t } = useTranslation()
    const [handleAdd] = useQueryPost(LeavesSuggestionService.store, ["add-leaves-suggestions"], {
        success: t("added-succssefully")
    }, undefined, () => {
        handleClose()
    })
    const onSubmit = (values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        handleAdd(values)
            .then((_) => {
                onSubmitProps.setSubmitting(false)
            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }

    return (
        <FormikContainer
            className=""
            arrayOfInputs={InputsArray}
            schema={schema}
            initialValues={initialValues}
            onSubmit={onSubmit}
            btnText={t("add")}
        />
    )
}

export default AddModal