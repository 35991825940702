import { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { DropDownMenu } from '../../../../Components';
import ActionsDropdown from './ActionsDropdown';
import { TableContext } from '../../../../Context/TableContext';

const AdministrativeDecisionsCols = (handleShow: (name: string) => void) => {
    const { t } = useTranslation()
    const { setRecordId } = useContext(TableContext)
    const columns = [
        {
            title: t("publisher-name"),
            dataIndex: 'user',
            key: 'user',
            render: (user: any, record: any) => user.name
        },
        {
            title: t("Title"),
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: t("posted-date"),
            dataIndex: 'updated_at',
            key: 'updated_at',
        },
        {
            title: t("actions"),
            dataIndex: 'id',
            key: 'id',
            render: (id: number) => <DropDownMenu className='action-table' arrayOfItems={ActionsDropdown(id, handleShow, t, setRecordId).items} children={t("Actions")} />
        },
    ];

    return { columns }
}

export default AdministrativeDecisionsCols