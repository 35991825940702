import { useParams } from "react-router-dom"
import useGet from "../../../../Custom Hooks/useGet"
import UserService from "../../../../Services/UserService"
import "./BannerDesign.css"
import { BannerBoxItems } from "../../../../Assets/Data/Dashboard/ProfileMangement/Index"
import { BannerBox } from "../../../../Components/ProfileMangement/Index"
import useQueryGet from "../../../../Custom Hooks/useQueryGet"
import { useContext } from "react"
import { UserContext } from "../../../../Context/UserContext"

const BannerDesign = () => {
    const params = useParams()
    const { setUser } = useContext(UserContext)
    const [data,] = useQueryGet(params.id != null ? () => UserService.getUserById(params.id) : () => UserService.getUser()
        , ["get-user-by-id", params.id], (res) => {
            setUser(res?.data?.data)
        })
    const renderHeighlightsBoxes = BannerBoxItems().items.map(box => (
        <BannerBox
            key={box.id}
            title={box.title}
            icon={box.icon}
            desc={box.desc} />
    ))
    return (
        <div className="flex-start-center-row mb-5">
            <div className={`img-profile-banner bg-full ${data?.data?.data?.image == null && "default-img"}`}
                style={{
                    backgroundImage: data?.data?.data?.image != null && "default-img" ? `url(https://crmapi.aetc.ae/${data?.data?.data?.image})` : ""
                }}></div>
            <div className="flex-start-start-col info">
                <h2>{data?.data?.data?.name}</h2>
                <div className="flex-center">
                    {renderHeighlightsBoxes}
                </div>
            </div>

        </div>
    )
}

export default BannerDesign