import { useTranslation } from "react-i18next"
import "./DeleteModal.css"
import { GoAlertFill } from "react-icons/go";
import { FC, useContext } from "react";
import useQueryPost from "../../Custom Hooks/useQueryPost";
import { TableContext } from "../../Context/TableContext";
type DeleteModalProps = {
    handleClose: () => void,
    endPointConfig: any,
    btnText: string
}
const DeleteModal: FC<DeleteModalProps> = ({ handleClose, endPointConfig, btnText }) => {
    const { t } = useTranslation()
    const { recordId } = useContext(TableContext)
    const [handleDelete] = useQueryPost(endPointConfig.endPoint, [...endPointConfig.queryKey], {
        success: t("deleted-succssefully")
    }, [endPointConfig.invalidate], () => {
        handleClose()
    })
    return (
        <div className="delete-modal flex-center-col">
            <div className="alert-delete">
                <GoAlertFill size={60} className="icon" />
            </div>
            <h3>{t("are-you-sure")}</h3>
            <p>{t("cannot-be-undone")}</p>
            <button className="btn-delete" onClick={() => handleDelete(recordId)}>{btnText}</button>
            <button className="btn-cancel" onClick={() => handleClose()}>{t("cancel")}</button>
        </div>
    )
}

export default DeleteModal