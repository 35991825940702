type AddDecisionInputsType = {
    id: number,
    as: string,
    name: string,
    control: string,
    type?: string,
    label: string
}

export const AddDecisionInputs: AddDecisionInputsType[] = [
    {
        id: 1,
        as: "",
        name: "title",
        label: "Title",
        control: "input",
    },
    {
        id: 2,
        control: "input",
        label: "Body",
        as: "textarea",
        name: "body"
    }
]

