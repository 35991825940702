import { InputsArrayTypes } from "../../../../Models/InputsArrayTypes";

const InputsArray: InputsArrayTypes[] = [
    {
        id: 1,
        control: "hour",
        label: "start",
        name: "start"
    },
    {
        id: 2,
        control: "hour",
        label: "end",
        name: "end"
    },
    {
        id: 3,
        control: "date-picker",
        label: "date",
        name: "date",
        dateFormat: "YYYY-MM-DD"
    },
    {
        id: 4,
        control: "input",
        as: "",
        label: "where_to_go",
        name: "where_to_go",
    },
    {
        id: 5,
        control: "input",
        as: "textarea",
        label: "why",
        name: "why",
    },

]
export default InputsArray