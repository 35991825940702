import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { TableContext } from "../../../Context/TableContext"
import useQueryGet from "../../../Custom Hooks/useQueryGet"
import OutdoorTasksService from "../../../Services/OutdoorTasksService"


const ShowOutdoorTaskModal = () => {
    const { t } = useTranslation()
    const { recordId } = useContext(TableContext)
    const [eventData] = useQueryGet(() => OutdoorTasksService.show(recordId), ["get-single-outdoor-task", recordId])
    return (
        <>
            <div className="flex-start-center-row mb-3">
                <label className="mb-0 mx-2">{t("name")} : </label>
                <p>{eventData?.data?.data?.user?.name}</p>
            </div>
            <div className="flex-start-center-row mb-3">
                <label className="mb-0 mx-2">{t("why")}</label>
                <p>{eventData?.data?.data?.why}</p>
            </div>
            <div className="flex-start-center-row mb-3">
                <label className="mb-0 mx-2">{t("where_to_go")}  </label>
                <p>{eventData?.data?.data?.where_to_go}</p>
            </div>
            <div className="flex-start-center-row mb-3">
                <label className="mb-0 mx-2">{t("date")} : </label>
                <p>{eventData?.data?.data?.date}</p>
            </div>
            <div className="flex-start-center-row mb-3">
                <label className="mb-0 mx-2">{t("startHour")} : </label>
                <p>{eventData?.data?.data?.start}</p>
            </div>
            <div className="flex-start-center-row mb-3">
                <label className="mb-0 mx-2">{t("endHour")} </label>
                <p>{eventData?.data?.data?.end}</p>
            </div>
        </>
    )
}

export default ShowOutdoorTaskModal