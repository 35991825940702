import { useTranslation } from "react-i18next"
import { OutdoorTaskInputs } from "../../../Assets/Data/Dashboard/Calendar"
import schema from "../../../Utils/Validations/OutdoorTasks/schema"
import { FormikContainer } from "../../Forms"
import { FC, useContext } from "react"
import { FormikHelpers, FormikValues } from "formik"
import useQueryPost from "../../../Custom Hooks/useQueryPost"
import OutdoorTasksService from "../../../Services/OutdoorTasksService"
import { UserContext } from "../../../Context/UserContext"

type AddModalProps = {
    handleClose: () => void
}

const initialValues = {
    date: "",
    where_to_go: "",
    why: "",
    start: "",
    end: "",
}

const AddModal: FC<AddModalProps> = ({ handleClose }) => {
    const { t } = useTranslation()
    const { loginedUserId } = useContext(UserContext)
    const [handleAdd] = useQueryPost(OutdoorTasksService.store, ["add-outdoor-task"], {
        success: t("added-succssefully"),
    }, undefined, () => {
        handleClose()
    })
    const onSubmit = (values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        handleAdd({ ...values, user_id: loginedUserId })
            .then((_) => {
                onSubmitProps.setSubmitting(false)
            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }
    return (
        <FormikContainer
            schema={schema}
            arrayOfInputs={OutdoorTaskInputs}
            className=""
            btnText={t("add")}
            initialValues={initialValues}
            onSubmit={onSubmit}
        />
    )
}

export default AddModal