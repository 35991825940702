import { useTranslation } from "react-i18next"
import { BoxTitle, BoxWithShadow, DynamicTable, MainBtn, ModalComponent } from "../../../Components"
import useModals from "../../../Custom Hooks/useModals"
import "./AdministrativeDecisions.css"
import MainPageModals from "../../../Assets/Data/Dashboard/MainPage/MainPageModals"
import { useContext, useState } from "react"
import { TableContext } from "../../../Context/TableContext"
import AdministrativeDecisionsService from "../../../Services/AdministrativeDecisionsService"
import useQueryGet from "../../../Custom Hooks/useQueryGet"
import AdministrativeDecisionsCols from "../../../Assets/Data/Dashboard/MainPage/AdministrativeDecisionsCols"

const AdministrativeDecisions = () => {
    const [page, setPage] = useState(1)
    const { t } = useTranslation()
    const { show, handleShow, handleClose } = useModals(
        {
            Decisions: false,
            EditDecisions: false,
            ShowDecision: false
        }
    )
    const { modals } = MainPageModals(handleClose, handleShow)
    const { setRecordId } = useContext(TableContext)

    const [data, isFetching] = useQueryGet(() => AdministrativeDecisionsService.get(page), ["administrative-decisions", page])
    console.log(data?.data)
    return (
        <BoxWithShadow additionalClass="last-news">
            <div className="flex-between-start">
                <BoxTitle title={t("Administrativedecisions")} />
                <MainBtn title={t("add")} onClick={() => {
                    setRecordId(null)
                    handleShow("Decisions")
                }} />
            </div>
            <DynamicTable
                columns={AdministrativeDecisionsCols(handleShow).columns}
                dataSource={data?.data?.data?.data} loading={isFetching}
                pagination={{
                    pageSize: data?.data?.data?.per_page,
                    total: data?.data?.data?.total,
                    onChange: (page) => setPage(page)
                }}
            />
            <ModalComponent show={show} handleClose={handleClose} arrayOfModals={modals} />
        </BoxWithShadow>
    )
}

export default AdministrativeDecisions