import { FC, useContext } from "react"
import { FormikContainer } from "../../Forms"
import { InputsArray } from "../../../Assets/Data/Dashboard/Courses"
import { useTranslation } from "react-i18next"
import addSchema from "../../../Utils/Validations/ProfileMangement/Courses/addSchema"
import { FormikHelpers, FormikValues } from "formik"
import { useParams } from "react-router-dom"
import { UserContext } from "../../../Context/UserContext"
import useQueryPost from "../../../Custom Hooks/useQueryPost"
import CoursesService from "../../../Services/CoursesService"

type AddModalProps = {
    handleClose: () => void
}

const initialValues = {
    start_date: "",
    end_date: "",
    course_name: "",
    objectives: "",
    meet_link: "",
    courses_platform_username: "",
    courses_platform_password: "",
    additional_information: ""
}
const AddModal: FC<AddModalProps> = ({ handleClose }) => {
    const { t } = useTranslation()
    const params = useParams()
    const { loginedUserId } = useContext(UserContext)
    const [handleAdd] = useQueryPost(CoursesService.add, ["add-course"], {
        success: t("added-succssefully"),
    }, ["get-courses"], () => {
        handleClose()
    })

    const onSubmit = (values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        handleAdd({ ...values, user_id: params.id ? params.id : loginedUserId })
            .then((_) => {
                onSubmitProps.setSubmitting(false)
            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }
    return (
        <FormikContainer
            arrayOfInputs={InputsArray}
            btnText={t("add")}
            className=""
            schema={addSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}
        />
    )
}

export default AddModal