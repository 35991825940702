import { ToastContainer } from "react-toastify"
import "./Departments.css"
import { BoxTitle, BoxWithShadow, DynamicTable, MainBtn, ModalComponent } from "../../../Components"
import { useTranslation } from "react-i18next"
import DepartmentsService from "../../../Services/DepartmentsService"
import useModals from "../../../Custom Hooks/useModals"
import { DepartmentsCols, DepartmentsModals } from "../../../Assets/Data/Dashboard/Departmens"
import useQueryGet from "../../../Custom Hooks/useQueryGet"

const Departments = () => {
    const { show, handleClose, handleShow } = useModals({
        addDepartment: false,
        editDepartment: false
    })
    const { t } = useTranslation()
    const [dataSource, , , loading] = useQueryGet(DepartmentsService.getAll, ["get-departments"])
    return (
        <>
            <BoxWithShadow additionalClass={`container-fluid users`}>
                <div className="flex-between-start">
                    <BoxTitle title={t("Departments")} />
                    <MainBtn title={t("add")} onClick={() => handleShow("addDepartment")} />
                </div>
                <DynamicTable
                    loading={loading}
                    dataSource={dataSource?.data?.data}
                    columns={DepartmentsCols(handleShow).cols}
                    pagination={false}
                />
                <ModalComponent show={show} handleClose={handleClose} arrayOfModals={DepartmentsModals(handleClose).modals} />
                <ToastContainer />
            </BoxWithShadow>
        </>

    )
}

export default Departments