import { useTranslation } from "react-i18next"
import Event from "../../../Assets/Images/Dashboard/MainPage/event-date-and-time-symbol-svgrepo-com.svg"
import { BoxWithShadow } from "../../../Components"
import { AxiosResponse } from "axios"
import { FC } from "react"
import "./DailyQuote.css"
type DailyQuoteProps = {
    details: AxiosResponse | undefined
}
const DailyQuote: FC<DailyQuoteProps> = ({ details }) => {
    const { i18n } = useTranslation()
    return (
        <div className="col-md-6">
            <BoxWithShadow additionalClass="daily-quote flex-center-col">
                <img src={Event} alt="" />
                <p>{i18n.language == "en" ? details?.data?.data?.daily_quote_en : details?.data?.data?.daily_quote_ar}</p>
            </BoxWithShadow>
        </div>
    )
}

export default DailyQuote