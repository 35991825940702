import { AxiosResponse } from "axios";
import { UseMutateAsyncFunction, useMutation, useQueryClient } from "react-query"
import { ToastErrorCon } from "../Components";
import { toast } from 'react-toastify';

type EndpointFunction = (data: any) => Promise<AxiosResponse<any, any>>;
const useQueryPost = (endPoint: EndpointFunction, mutateKey: any[], toastifyConfig?: {
    success: string,

}, invalidate?: any[], onSuccess?: (res?: AxiosResponse) => void): [
        mutateAsync: UseMutateAsyncFunction<AxiosResponse<any, any>, any, any, void>,
        data: AxiosResponse<any, any> | undefined,
        isLoading: boolean
    ] => {
    const queryClient = useQueryClient()
    const { mutateAsync, data, isLoading } = useMutation({
        mutationFn: endPoint,
        mutationKey: [...mutateKey],
        onSuccess: (res: AxiosResponse) => {
            console.log(res)
            if (invalidate) {
                queryClient.invalidateQueries([...invalidate])
            }
            if (toastifyConfig) {
                toast.dismiss();
                toast.success(`${toastifyConfig.success}`, {
                    position: 'bottom-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
            if (onSuccess) {
                onSuccess(res)
            }
        },
        onError: (err: any) => {
            console.log(err)
            if (err.response.status == 401 || err.response.status == 422) {
                toast.dismiss()
                toast.error(<ToastErrorCon>
                    {Object.keys(err.response.data.error).map(item => (
                        <p key={Math.random()}>{err.response.data.error[item]}</p>
                    ))}
                </ToastErrorCon>, {
                    position: 'bottom-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        },
        onMutate: () => {
            if (toastifyConfig) {
                toast.loading('Processing...', {
                    position: 'bottom-right',
                    autoClose: 10000,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        },
    })
    return [mutateAsync, data, isLoading]
}

export default useQueryPost