import { useTranslation } from "react-i18next"
import { BoxTitle, BoxWithShadow, Loading } from "../../../../Components"
import "./Profile.css"
import { useContext } from "react"
import { UserContext } from "../../../../Context/UserContext"
import { ShowArray } from "../../../../Assets/Data/Dashboard/ProfileMangement/Profile"
import { ShowDataDesign } from "../../../../Components/ProfileMangement/Profile"

const Profile = () => {
    const { t } = useTranslation()
    const { user } = useContext(UserContext)
    const renderData = ShowArray().items.map(data => (
        <ShowDataDesign label={data.label} data={data.data} key={data.id} type={user?.type} isOptional={data?.isOptional} />
    ))
    return (
        <>

            <BoxWithShadow additionalClass="profile-details container-fluid">
                <div className="flex-between">
                    <BoxTitle title={t("profile-details")} />
                </div>
                <div className="flex-start-start-col profile-details-box">
                    {renderData}
                </div>
            </BoxWithShadow>
        </>

    )
}

export default Profile