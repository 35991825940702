import { BoxTitle, BoxWithShadow, MainBtn, ModalComponent } from "../../../Components"
import "./Holidays.css"
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import useModals from "../../../Custom Hooks/useModals";
import { CalendarModals } from "../../../Assets/Data/Dashboard/Calendar";
import { Calendar } from "../../../Components/Calendar";
const Holidays = () => {
    const { t, i18n } = useTranslation()
    const { show, handleShow, handleClose } = useModals({
        addOutdoorTask: false,
        addLeaveSuggestion: false,
        addLeave: false,
        showLeave: false,
        showSpecialEvent: false,
        showLeavesSuggestion: false,
        showOutdoorTask: false
    })
    const { modals } = CalendarModals(handleClose)
    return (
        <>
            <BoxWithShadow additionalClass={`holidays-con holidays-main-con mt-5 ${i18n.language == "ar" && "ar"}`}>
                <div className="flex-between mb-5">
                    <BoxTitle title={t("holidays")} />
                    <div className={`flex-between holidays-buttons ${i18n.language == "ar" && "ar"}`}>
                        <MainBtn title={t("AddLeave")} onClick={() => handleShow("addLeave")} />
                        <MainBtn title={t("AddOutdoortask")} onClick={() => handleShow("addOutdoorTask")} />
                        <MainBtn title={t("AddLeaveSuggestion")} onClick={() => handleShow("addLeaveSuggestion")} />
                    </div>
                </div>
                <Calendar handleShow={handleShow} />
                <ModalComponent arrayOfModals={modals} show={show} handleClose={handleClose} />
                <ToastContainer />
            </BoxWithShadow>
        </>

    )
}

export default Holidays