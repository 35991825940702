import { DeleteModal } from '../../../../Components'
import { useTranslation } from 'react-i18next'
import CoursesService from '../../../../Services/CoursesService'
import { AddModal, EditModal } from '../../../../Components/ProfileMangement/Courses'

const CoursesModals = (handleClose: (name: string) => void) => {
    const { t } = useTranslation()
    const modals = [
        {
            id: 1,
            name: "addCourse",
            title: t("add"),
            children: <AddModal handleClose={() => handleClose("addCourse")} />

        },
        {
            id: 2,
            name: "editCourse",
            title: t("edit"),
            children: <EditModal handleClose={() => handleClose("editCourse")} />
        },
        {
            id: 3,
            name: "Delete",
            title: t("Delete"),
            children: <DeleteModal
                btnText={t("Delete")}
                endPointConfig={{
                    invalidate: "get-courses",
                    queryKey: ["delete-course"],
                    endPoint: CoursesService.delete
                }}
                handleClose={() => handleClose("Delete")} />
        }
    ]

    return { modals }
}

export default CoursesModals