import { InputsArrayTypes } from "../../../../../Models/InputsArrayTypes"


const InputsArray: InputsArrayTypes[] = [
    {
        id: 1,
        control: "File",
        label: "File",
        name: "files[0].file"
    },
    {
        id: 2,
        control: "input",
        as: "",
        label: "Description",
        name: "files[0].desc"
    },
]
export default InputsArray