import DatePicker from "react-datepicker";
import { BoxTitle, BoxWithShadow, DynamicTable } from "../../../Components";
import "./SearchTable.css"
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import useQueryPost from "../../../Custom Hooks/useQueryPost";
import PayrollService from "../../../Services/PayrollService";
import { SearchTableCols } from "../../../Assets/Data/Dashboard/ExportSalary"

const SearchTable = () => {
    const [selectedDate, setSelectedDate] = useState<any>(null);
    const { t } = useTranslation()
    const [handleFilteredData, data, loading] = useQueryPost(PayrollService.filteredData, ["filter-salary-table"],
        undefined, ["filter-salary-table"]
    )
    useEffect(() => {
        if (selectedDate == undefined) {
            handleFilteredData(null)
        }
        else {
            handleFilteredData(selectedDate)
        }

    }, [selectedDate])
    return (
        <BoxWithShadow additionalClass={`container-fluid users filtered-salary mb-4`}>
            <div className="flex-between-start ">
                <BoxTitle title={t("Salary")} />
                <div className="form-control">
                    <DatePicker
                        selected={selectedDate}
                        dateFormat="yyyy-MM"
                        className="form-control"
                        showYearDropdown
                        onChange={(val) => {
                            if (val) {
                                // Convert Date object to string in YYYY/MM/DD format
                                const formattedDate = moment(val).format('YYYY-MM');

                                // Update the form field value
                                setSelectedDate(formattedDate);
                            }
                        }}
                    />
                </div>
            </div>
            <DynamicTable
                loading={loading}
                dataSource={data?.data?.data}
                columns={SearchTableCols().columns}
                pagination={false}
            />

        </BoxWithShadow>
    )
}

export default SearchTable