import { Form, Formik, FormikHelpers, FormikProps, FormikValues } from 'formik'
import { FC } from 'react'
import "./FormikContainer.css"
import { ObjectSchema } from 'yup'
import { FormikControl, IsVisibleInput } from '../'
import { useTranslation } from 'react-i18next'


type FormikContainerProps = {
    initialValues: FormikValues,
    onSubmit: (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>) => void,
    schema: ObjectSchema<any>,
    className: string,
    arrayOfInputs?: any[],
    btnText: string,
    isEnableReinit?: boolean,
    OptionalConfig?: {
        isNeedCheck: boolean,
        checkName: string,
        checkValue: string
    },
    optionalFileds?: string[]

}
const FormikContainer: FC<FormikContainerProps> = ({ initialValues, onSubmit, schema, className, arrayOfInputs, btnText, isEnableReinit, OptionalConfig, optionalFileds }) => {
    const { t } = useTranslation()
    const renderInputs = (formik: FormikProps<FormikValues>) => arrayOfInputs?.map(input => {
        if (OptionalConfig?.isNeedCheck == true) {
            return <IsVisibleInput key={input.id} formik={formik} OptionalConfig={OptionalConfig} input={input} optionalFileds={optionalFileds} />
        }
        return <FormikControl
            key={input.id}
            control={input.control}
            name={input.name}
            label={input.label}
            as={input.as}
            arrayOfOptions={input.arrayOfOptions}
            isTwoCols={input.isTwoCols}
            type={input.type}
            value={formik.values[input.name]}
            formik={formik}
            dateFormat={input.dateFormat}
            multiSelectOptions={input.multiSelectOptions}
            displayValue={input.displayValue}
        />
    }

    )
    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={schema}
                enableReinitialize={isEnableReinit}
            >
                {(formik) => {
                    console.log(formik.errors)
                    return (
                        <Form className={className}>
                            {renderInputs(formik)}

                            <div className='btn-form-container'>
                                <button
                                    className='btn btn-primary'
                                    disabled={formik.isSubmitting || !formik.isValid}>
                                    {t(btnText)}
                                </button>
                            </div>
                        </Form>
                    )
                }
                }
            </Formik>

        </>
    )
}

export default FormikContainer