import { ErrorMessage, Field } from "formik";
import "./DatePickerInp.css"
import { TextError } from "../../";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
type DatePickerProps = {
    label: string,
    dateFormat?: string,
    name: string,
    isTwoCols?: boolean
}

const DatePickerInp: FC<DatePickerProps> = ({ label, dateFormat, name, isTwoCols }) => {
    const { t } = useTranslation()
    if (isTwoCols == true) {
        return <div className="row mb-3">
            <div className="col">
                <h6>{t(label)}</h6>
            </div>
            <div className="col">
                <div className="form-control dynamic-input">
                    <Field name={name} id={label} className="form-control" >
                        {({ field, form }: any) => (
                            <DatePicker {...field}
                                dateFormat={dateFormat}
                                className="form-control"
                                showYearDropdown
                                selected={field.value}
                                onChange={(val) => {
                                    if (val) {
                                        // Convert Date object to string in YYYY/MM/DD format
                                        const formattedDate = moment(val).format(dateFormat);
                                        // Update the form field value
                                        form.setFieldValue(name, formattedDate);
                                    }
                                }}
                            />
                        )}
                    </Field>
                    <ErrorMessage name={name} component={TextError} />
                </div>
            </div>
        </div>
    }
    else {
        return (
            <div className="form-control dynamic-input">
                <label htmlFor={label} className="form-label">{t(label)}</label>
                <Field name={name} id={label} className="form-control" >
                    {({ field, form }: any) => (
                        <DatePicker {...field}
                            dateFormat={dateFormat}
                            className="form-control"
                            showYearDropdown
                            selected={field.value}
                            onChange={(val) => {
                                if (val) {
                                    // Convert Date object to string in YYYY/MM/DD format
                                    const formattedDate = moment(val).format(dateFormat);
                                    // Update the form field value
                                    form.setFieldValue(name, formattedDate);
                                }
                            }}
                        />
                    )}
                </Field>
                <ErrorMessage name={name} component={TextError} />
            </div>
        )
    }
}

export default DatePickerInp