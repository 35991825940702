import "./EditMainPage.css"
import { ToastContainer } from "react-toastify"
import { AddEmployerOfTheMonth, WordEventFromCompanyBox } from "../../../Sections/EditMainPage"

const EditMainPage = () => {


    return (
        <>

            <AddEmployerOfTheMonth />
            <WordEventFromCompanyBox />
            <ToastContainer />
        </>
    )
}

export default EditMainPage