import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { TableContext } from "../../../Context/TableContext"
import useQueryGet from "../../../Custom Hooks/useQueryGet"
import SpecialEventsService from "../../../Services/SpecialEventsService"


const ShowSpecialEventModal = () => {
    const { t } = useTranslation()
    const { recordId } = useContext(TableContext)
    const [eventData] = useQueryGet(() => SpecialEventsService.show(recordId), ["get-single-special-event", recordId])
    return (
        <>
            <div className="flex-start-center-row mb-3">
                <label className="mb-0 mx-2">{t("Title")} : </label>
                <p>{eventData?.data?.data?.title}</p>
            </div>
            <div className="flex-start-center-row mb-3">
                <label className="mb-0 mx-2">{t("Body")} : </label>
                <p>{eventData?.data?.data?.body}</p>
            </div>
            <div className="flex-start-center-row mb-3">
                <label className="mb-0 mx-2">{t("start")} : </label>
                <p>{eventData?.data?.data?.start}</p>
            </div>
            <div className="flex-start-center-row mb-3">
                <label className="mb-0 mx-2">{t("end")} : </label>
                <p>{eventData?.data?.data?.end}</p>
            </div>
        </>
    )
}

export default ShowSpecialEventModal