import { useTranslation } from "react-i18next";
import { BoxTitle, BoxWithShadow } from "../../../Components";
import "./AddEmployerOfTheMonth.css"
import { FormikContainer } from "../../../Components/Forms";
import AddEmpOfTheMonthSchema from "../../../Utils/Validations/EditMainPage/AddEmpOfTheMonthSchema";
import { InputsArray } from "../../../Assets/Data/Dashboard/EditMainPage";
import { FormikHelpers, FormikValues } from "formik";
import useQueryPost from "../../../Custom Hooks/useQueryPost";
import EmployerOfTheMonthService from "../../../Services/EmployerOfTheMonthService";

const initialValues = {
    month: "",
    employer: "",
    message_en: "",
    message_ar: ""
}
const AddEmployerOfTheMonth = () => {
    const { t } = useTranslation()
    const [handleSubmit] = useQueryPost(EmployerOfTheMonthService.store, ["add-emolyer-of-the-month"], {
        success: t("edited-succssefully")
    })
    const onSubmit = (values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        handleSubmit(values)
            .then((_) => {
                onSubmitProps.setSubmitting(false)
            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }
    return (
        <BoxWithShadow additionalClass={`add-employer-of-the-month-box container-fluid mb-3 `}>
            <BoxTitle title={t("Employerofthemonth")} />
            <FormikContainer
                schema={AddEmpOfTheMonthSchema}
                className=""
                arrayOfInputs={InputsArray().inputs}
                initialValues={initialValues}
                onSubmit={onSubmit}
                btnText={t("edit")}
            />
        </BoxWithShadow>
    )
}

export default AddEmployerOfTheMonth