import "./MainPage.css"
import { ToastContainer } from "react-toastify"
import MainPageService from "../../../Services/MainPageService"
import { AdministrativeDecisions, DailyQuote, EmplyerOfTheMonth, WordFromCompany } from "../../../Sections/MainPage"
import useQueryGet from "../../../Custom Hooks/useQueryGet"

const MainPage = () => {
    const [details,] = useQueryGet(MainPageService.get, ["main-page"])

    return (
        <>
            <div className="container-fluid main-page-con">
                <EmplyerOfTheMonth details={details} />
                <div className="row mb-5">
                    <WordFromCompany details={details} />
                    <DailyQuote details={details} />
                </div>
                <AdministrativeDecisions />
            </div>
            <ToastContainer />
        </>
    )
}

export default MainPage