import { Outlet } from 'react-router-dom'
import UserContextProvider from './Context/UserContext'
import TableContextProvider from './Context/TableContext'
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

const Root = () => {
    return (
        <div className="App">
            <QueryClientProvider client={queryClient}>
                <UserContextProvider>
                    <TableContextProvider>
                        <Outlet />
                    </TableContextProvider>
                </UserContextProvider>
            </QueryClientProvider>
        </div>
    )
}

export default Root