import "./Tabs.css"
import { TabsLinks } from "../../../../Assets/Data/Dashboard/ProfileMangement/Index"
import { TabsItem } from "../../../../Components/ProfileMangement/Index"

const Tabs = () => {
    const renderLinks = TabsLinks().items.map(link => (
        <TabsItem to={link.to} title={link.title} key={link.id} isOptional={link.isOptional} />
    ))
    return (
        <ul className="tabs">
            {renderLinks}
        </ul>
    )
}

export default Tabs