import { useTranslation } from "react-i18next"

const MainNavModals = () => {
    const { t } = useTranslation()
    const modals = [
        {
            id: 1,
            name: "notification",
            title: t("notification"),
            children: <></>
        },
    ]

    return { modals }
}

export default MainNavModals