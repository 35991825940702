import { useTranslation } from "react-i18next"
import { BoxTitle, BoxWithShadow, DynamicTable } from "../../../Components"
import "./LeavesRequestsTable.css"
import useQueryGet from "../../../Custom Hooks/useQueryGet"
import LeavesServices from "../../../Services/LeavesServices"
import { LeavesRequestsTableCols } from "../../../Assets/Data/Dashboard/LeavesRequests"

const LeavesRequestsTable = () => {
    const { t } = useTranslation()
    const [dataSource, , , loading] = useQueryGet(LeavesServices.getLeavesRequests, ["get-leaves-requests"])

    return (
        <BoxWithShadow additionalClass={`container-fluid users mb-4`}>
            <div className="flex-between">
                <BoxTitle title={t("Leaves-Requests")} />
            </div>
            <DynamicTable
                loading={loading}
                dataSource={dataSource?.data?.data}
                columns={LeavesRequestsTableCols(t).columns}
            />
        </BoxWithShadow>
    )
}

export default LeavesRequestsTable