import { InputsArrayTypes } from "../../../../Models/InputsArrayTypes";

const InputsArray: InputsArrayTypes[] = [
    {
        id: 1,
        control: "date-picker",
        label: "start",
        name: "start_date",
        dateFormat: "YYYY-MM-DD"
    },
    {
        id: 2,
        control: "date-picker",
        label: "end",
        name: "end_date",
        dateFormat: "YYYY-MM-DD"
    }
]
export default InputsArray