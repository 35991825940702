import { ModalComponent } from "../../../../Components"
import "./Salary.css"
import { useParams } from "react-router-dom"
import { useContext } from "react"
import { UserContext } from "../../../../Context/UserContext"
import UserService from "../../../../Services/UserService"
import useModals from "../../../../Custom Hooks/useModals"
import { ToastContainer } from "react-toastify"
import { SalaryModals } from "../../../../Assets/Data/Dashboard/ProfileMangement/Salary"
import { BonusTable, DeductionTable, SalaryIncreaseTable, SalaryTable } from "../../../../Sections/ProfileMangement/Salary"
import useQueryGet from "../../../../Custom Hooks/useQueryGet"
const Salary = () => {
    const params = useParams()
    const { userId } = useContext(UserContext)
    const [details, , , loading] = useQueryGet(params.id != null ? () => UserService.payrollDetails(params.id) : () => UserService.payrollDetails(userId),
        ["get-payroll-details"])

    const { show, handleShow, handleClose } = useModals({
        addSalaryIncreases: false,
        editSalaryIncreases: false,
        addDeduction: false,
        editDeduction: false,
        addBonus: false,
        editBonus: false,
        Delete: false
    })
    const { modals } = SalaryModals(handleClose)
    return (
        <>
            <SalaryTable
                loading={loading}
                payrolls={details?.data?.data?.payrolls}
            />
            <SalaryIncreaseTable
                loading={loading}
                salaryIncreases={details?.data?.data?.salary_increases}
                handleShow={handleShow}
            />
            <div className="row row-cols-2 mb-3">
                <DeductionTable
                    handleShow={handleShow}
                    loading={loading}
                    deductions={details?.data?.data?.deductions} />
                <BonusTable
                    handleShow={handleShow}
                    loading={loading}
                    bonuses={details?.data?.data?.bonuses} />
            </div>

            <ModalComponent arrayOfModals={modals} handleClose={handleClose} show={show} />
            <ToastContainer />

        </>
    )
}

export default Salary