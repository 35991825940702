import { useTranslation } from 'react-i18next'

const SearchTableCols = () => {
    const { t } = useTranslation()
    const columns = [
        {
            title: t("name"),
            dataIndex: 'user',
            key: 'user',
            render: (user: any) => user.name
        },
        {
            title: t("BaseSalary"),
            dataIndex: 'base_salary',
            key: 'base_salary',
        },
        {
            title: t("NetSalary"),
            dataIndex: 'net_salary',
            key: 'net_salary',

        },
        {
            title: t("TotalBonus"),
            dataIndex: 'total_bonuses',
            key: 'total_bonuses',

        },
        {
            title: t("TotalDeduction"),
            dataIndex: 'total_deductions',
            key: 'total_deductions',


        },
        {
            title: t("TotalSalaryIncrease"),
            dataIndex: 'total_salary_increase',
            key: 'total_salary_increase',
        },
        {
            title: t("PayrollDate"),
            dataIndex: 'payroll_date',
            key: 'payroll_date',

        },

    ]

    return { columns }
}

export default SearchTableCols