import { InputsArrayTypes } from "../../../../Models/InputsArrayTypes";

const InputsArray: InputsArrayTypes[] = [
    {
        id: 1,
        control: "input",
        as: "",
        label: "course-name",
        name: "course_name"
    },
    {
        id: 2,
        control: "date-picker",
        label: "start",
        name: "start_date",
        dateFormat: "YYYY-MM-DD"
    },
    {
        id: 3,
        control: "date-picker",
        label: "end",
        name: "end_date",
        dateFormat: "YYYY-MM-DD"
    },
    {
        id: 4,
        control: "input",
        as: "textarea",
        label: "objectives",
        name: "objectives"
    },
    {
        id: 5,
        control: "input",
        as: "",
        label: "meet-link",
        name: "meet_link"
    },
    {
        id: 6,
        control: "input",
        as: "",
        label: "courses_platform_username",
        name: "courses_platform_username"
    },
    {
        id: 7,
        control: "input",
        as: "",
        label: "courses_platform_password",
        name: "courses_platform_password"
    },
    {
        id: 8,
        control: "input",
        as: "",
        label: "additional_information",
        name: "additional_information"
    },


]
export default InputsArray