import { InputsArrayTypes } from "../../../../../Models/InputsArrayTypes";

const InputsArray: InputsArrayTypes[] = [
    {
        id: 1,
        control: "input",
        as: "",
        label: "old-password",
        name: "old_password",
        isTwoCols: true
    },
    {
        id: 2,
        control: "input",
        as: "",
        label: "password",
        name: "password",
        isTwoCols: true
    },
    {
        id: 3,
        control: "input",
        as: "",
        label: "password-confirmation",
        name: "password_confirmation",
        isTwoCols: true
    }
]
export default InputsArray