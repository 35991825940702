import { useTranslation } from "react-i18next"
import "./Select.css"
import { FC } from "react"
import { ErrorMessage, Field } from "formik"
import { TextError } from "../../"
type SelectProps = {
    label: string,
    name: string,
    arrayOfOptions?: {
        value: string,
        title: string,
        id: number
    }[],
    isTwoCols?: boolean
}
const Select: FC<SelectProps> = ({ label, name, arrayOfOptions, isTwoCols }) => {
    const { t } = useTranslation()
    const renderOptions = arrayOfOptions?.map(option => (
        <option value={option.value} key={option.id}>{option.title}</option>
    ))
    if (isTwoCols) {
        return <div className="row mb-3">
            <div className="col">
                <h6>{t(label)}</h6>
            </div>
            <div className="col">
                <div className="form-control">
                    <Field as="select" name={name} id={label} className="form-select">
                        <option disabled value="" hidden>
                            Select
                        </option>
                        {renderOptions}
                    </Field>
                    <ErrorMessage name={name} component={TextError} />
                </div>
            </div>
        </div>
    }
    else {
        return (
            <div className="form-control">
                <label htmlFor={label}>
                    {t(label)}
                </label>
                <Field as="select" name={name} id={label} className="form-select">
                    <option disabled value="" hidden>
                        Select
                    </option>
                    {renderOptions}
                </Field>
                <ErrorMessage name={name} component={TextError} />
            </div>
        )
    }

}

export default Select