import { useTranslation } from "react-i18next"
import { Deduction_BounsInputs } from "../../../../Assets/Data/Dashboard/ProfileMangement/Salary"
import addSchema from "../../../../Utils/Validations/ProfileMangement/Salary/Deduction_Bouns/addSchema"
import { FormikContainer } from "../../../Forms"
import useQueryPost from "../../../../Custom Hooks/useQueryPost"
import DeductionService from "../../../../Services/DeductionService"
import { FC, useContext } from "react"
import { useParams } from "react-router-dom"
import { UserContext } from "../../../../Context/UserContext"
import { FormikHelpers, FormikValues } from "formik"

const initialValues = {
    date: "",
    amount: "",
    description: ""
}
type AddModalProps = {
    handleClose: () => void
}

const AddModal: FC<AddModalProps> = ({ handleClose }) => {
    const { t } = useTranslation()
    const params = useParams()
    const { loginedUserId } = useContext(UserContext)
    const [handleAdd] = useQueryPost(DeductionService.store, ["add-deduction"], {
        success: t("added-succssefully"),
    }, ["get-payroll-details"], () => {
        handleClose()
    })
    const onSubmit = (values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        handleAdd({ ...values, user_id: params.id ? params.id : loginedUserId })
            .then((_) => {
                onSubmitProps.setSubmitting(false)
            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }
    return (
        <FormikContainer
            arrayOfInputs={Deduction_BounsInputs}
            className=""
            schema={addSchema}
            initialValues={initialValues}
            btnText={t("add")}
            onSubmit={onSubmit}
        />
    )
}

export default AddModal