import { useTranslation } from 'react-i18next'
import { EditModal, ShowModal } from '../../../../Components/OutdoorTasks'
import { DeleteModal } from '../../../../Components'
import OutdoorTasksService from '../../../../Services/OutdoorTasksService'

const OutdoorModals = (handleClose: (name: string) => void) => {
    const { t } = useTranslation()
    const modals = [
        {
            id: 1,
            name: "showOutdoorTask",
            title: t("show"),
            children: <ShowModal />
        },
        {
            id: 2,
            name: "editOutdoorTask",
            title: t("edit"),
            children: <EditModal handleClose={handleClose} />
        },
        {
            id: 3,
            name: "Delete",
            title: t("Delete"),
            children: <DeleteModal
                btnText={t("Delete")}
                endPointConfig={{
                    invalidate: "get-all-outdoor-tasks",
                    queryKey: ["delete-decision"],
                    endPoint: OutdoorTasksService.delete
                }}
                handleClose={() => handleClose("Delete")} />
        }
    ]

    return { modals }
}

export default OutdoorModals