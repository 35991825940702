import { useTranslation } from 'react-i18next'
import { AddModal, EditModal } from '../../../../Components/Department'


const DepartmentsModals = (handleClose: (name: string) => void) => {
    const { t } = useTranslation()
    const modals = [
        {
            id: 1,
            name: "editDepartment",
            title: t("edit"),
            children: <EditModal handleClose={() => handleClose("editDepartment")} />
        },
        {
            id: 2,
            name: "addDepartment",
            title: t("add"),
            children: <AddModal handleClose={() => handleClose("addDepartment")} />
        },
    ]

    return { modals }
}

export default DepartmentsModals