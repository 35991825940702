import { DropDownMenu, MainBtn } from "../../../../Components"
import useQueryPost from "../../../../Custom Hooks/useQueryPost"
import OutdoorTasksService from "../../../../Services/OutdoorTasksService"
import ActionsDropdown from "./ActionsDropdown"
import { useContext } from "react"
import { TableContext } from "../../../../Context/TableContext"

const OutdoorTasksCols = (t: (key: string) => string, handleShow: (name: string) => void) => {
    const { setRecordId } = useContext(TableContext)
    const [handleApprove] = useQueryPost(OutdoorTasksService.approve, ["approve-outdoor-task"], {
        success: t("approved-successfully"),
    }, ["get-all-outdoor-tasks"])
    const columns = [
        {
            title: t("name"),
            dataIndex: 'user',
            key: 'user',
            render: (user: any) => user?.name
        },
        {
            title: t("date"),
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: t("start"),
            dataIndex: 'start',
            key: 'start',
        },
        {
            title: t("end"),
            dataIndex: 'end',
            key: 'end',
        },
        {
            title: 'Approve',
            dataIndex: 'id',
            key: 'id',
            render: (id: number, record: any) => {
                return <>
                    {record.approved == 1 ? t("approved") : <MainBtn title={t("approved")} onClick={() => handleApprove(id)} />}
                </>
            }
        },

        {
            title: t("actions"),
            dataIndex: 'id',
            key: 'id',
            render: (id: number) => <>
                <DropDownMenu
                    children={t("actions")}
                    arrayOfItems={ActionsDropdown(id, handleShow, t, setRecordId).items}
                    className="action-table"
                />
            </>
        },
    ]
    return { columns }
}

export default OutdoorTasksCols