import React, { FC } from "react"
import "./BannerBox.css"
import { useTranslation } from "react-i18next"
type BannerBoxProps = {
    title: string,
    icon: React.ReactNode,
    desc: string
}
const BannerBox: FC<BannerBoxProps> = ({ title, icon, desc }) => {
    const { t } = useTranslation()
    return (
        <div className="heighlights-box flex-center-start-col ">
            <div className="success-text flex-start-center">
                <span className="flex-center">{icon}</span>
                {desc}
            </div>
            <p className="m-0">{t(title)}</p>
        </div>
    )
}

export default BannerBox