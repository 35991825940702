import { FC, useContext } from "react"
import { Deduction_BounsInputs } from "../../../../Assets/Data/Dashboard/ProfileMangement/Salary"
import { FormikContainer } from "../../../Forms"
import { useTranslation } from "react-i18next"
import editSchema from "../../../../Utils/Validations/ProfileMangement/Salary/Deduction_Bouns/editSchema"
import { FormikHelpers, FormikValues } from "formik"
import { useParams } from "react-router-dom"
import { UserContext } from "../../../../Context/UserContext"
import { TableContext } from "../../../../Context/TableContext"
import DeductionService from "../../../../Services/DeductionService"
import useQueryPost from "../../../../Custom Hooks/useQueryPost"
import useQueryGet from "../../../../Custom Hooks/useQueryGet"


const initialValues = {
    date: "",
    amount: "",
    description: ""
}
type EditModalProps = {
    handleClose: () => void
}
const EditModal: FC<EditModalProps> = ({ handleClose }) => {
    const { t } = useTranslation()
    const params = useParams()
    const { loginedUserId } = useContext(UserContext)
    const { recordId } = useContext(TableContext)
    const [handleEdit] = useQueryPost(DeductionService.edit, ["edit-deduction"], {
        success: t("edited-succssefully"),
    }, ["get-payroll-details"], () => {
        handleClose()
    })
    const [Deduction] = useQueryGet(() => DeductionService.show(recordId), ["get-single-Deduction", recordId])

    const onSubmit = (values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        handleEdit({ ...values, user_id: params.id ? params.id : loginedUserId, id: recordId })
            .then((_) => {
                onSubmitProps.setSubmitting(false)
            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }
    return (
        <FormikContainer
            arrayOfInputs={Deduction_BounsInputs}
            className=""
            schema={editSchema}
            initialValues={Deduction?.data?.data || initialValues}
            btnText={t("edit")}
            onSubmit={onSubmit}
            isEnableReinit={true}
        />
    )
}

export default EditModal