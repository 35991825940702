import { useTranslation } from "react-i18next"
import { InputsArrayTypes } from "../../../../Models/InputsArrayTypes"


const InputsArray = (roles: any, mangerOptions: any) => {
    const { t } = useTranslation()
    const inputs: InputsArrayTypes[] = [
        {
            id: 1,
            control: "img",
            label: "avatar",
            name: "image"
        },
        {
            id: 2,
            control: "select",
            label: "type",
            name: "type",
            arrayOfOptions: [
                {
                    id: 1,
                    title: t("on-site"),
                    value: "On-site"
                },
                {
                    id: 2,
                    title: t("remotely"),
                    value: "Remotely"
                }
            ]
        },
        {
            id: 3,
            control: "input",
            as: "",
            label: "name",
            name: "name"
        },
        {
            id: 4,
            control: "input",
            as: "",
            label: "work_description",
            name: "work_description"
        },
        {
            id: 5,
            control: "input",
            as: "",
            label: "BaseSalary",
            name: "base_salary"
        },
        {
            id: 6,
            control: "input",
            as: "",
            label: "email",
            name: "email",
            type: "email"
        },
        {
            id: 7,
            control: "select",
            label: "BloodType",
            name: "blood_type",
            arrayOfOptions: [
                {
                    id: 1,
                    title: "A+",
                    value: "A+"
                },
                {
                    id: 2,
                    title: "A-",
                    value: "A-"
                },
                {
                    id: 3,
                    title: "B+",
                    value: "B+"
                },
                {
                    id: 4,
                    title: "B-",
                    value: "B-"
                },
                {
                    id: 5,
                    title: "O+",
                    value: "O+"
                },
                {
                    id: 6,
                    title: "O-",
                    value: "O-"
                },
                {
                    id: 7,
                    title: "AB+",
                    value: "AB+"
                },
                {
                    id: 8,
                    title: "AB-",
                    value: "AB-"
                }
            ]
        },
        {
            id: 8,
            control: "input",
            as: "",
            label: "Position",
            name: "position",
        },
        {
            id: 9,
            control: "input",
            as: "",
            label: "university_certificate",
            name: "university_certificate",
        },
        {
            id: 10,
            control: "input",
            as: "",
            label: "address-uae",
            name: "address_uae",
            isConditionalRender: true
        },
        {
            id: 11,
            control: "date-picker",
            label: "DateOfBirth",
            name: "date_of_birth",
            dateFormat: "YYYY/MM/DD"
        },
        {
            id: 12,
            control: "input",
            as: "",
            label: "mobile",
            name: "mobile",
        },
        {
            id: 13,
            control: "input",
            as: "",
            label: "Nationality",
            name: "nationality",
        },
        {
            id: 14,
            control: "input",
            as: "",
            label: "HomeCountry",
            name: "home_country",
        },
        {
            id: 15,
            control: "input",
            as: "",
            label: "HomeAddress",
            name: "address_home",
        },
        {
            id: 16,
            control: "input",
            as: "",
            label: "personal_id",
            name: "personal_id",
        },
        {
            id: 17,
            control: "input",
            as: "",
            label: "emergency-contact-person",
            name: "emergency_contact_person",
            isConditionalRender: true
        },
        {
            id: 18,
            control: "input",
            as: "",
            label: "emergency-contact-mobile",
            name: "emergency_contact_mobile",
            isConditionalRender: true
        },
        {
            id: 19,
            control: "input",
            as: "",
            label: "emergency-contact-relation",
            name: "emergency_contact_relation",
            isConditionalRender: true
        },
        {
            id: 20,
            control: "input",
            as: "",
            label: "passport-number",
            name: "passport_number",
            isConditionalRender: true
        },
        {
            id: 21,
            control: "date-picker",
            label: "passport_expiry_date",
            name: "passport_expiry_date",
            dateFormat: "YYYY/MM/DD",
            isConditionalRender: true
        },
        {
            id: 22,
            control: "input",
            as: "",
            label: "passport-place-of-issue",
            name: "passport_place_of_issue",
            isConditionalRender: true
        },
        {
            id: 23,
            control: "input",
            as: "",
            label: "visa-type",
            name: "visa_type",
            isConditionalRender: true
        },
        {
            id: 24,
            control: "date-picker",
            label: "visa-expiry-date",
            name: "visa_expiry_date",
            dateFormat: "YYYY/MM/DD",
            isConditionalRender: true
        },
        {
            id: 25,
            control: "input",
            as: "",
            label: "visa-place-of-issue",
            name: "visa_place_of_issue",
            isConditionalRender: true
        },
        {
            id: 26,
            control: "input",
            as: "",
            label: "visa-id-number",
            name: "visa_id_number",
            isConditionalRender: true
        },
        {
            id: 27,
            control: "date-picker",
            label: "labor-card-issue-date",
            name: "labor_card_issue_date",
            dateFormat: "YYYY/MM/DD",
            isConditionalRender: true
        },
        {
            id: 28,
            control: "date-picker",
            label: "labor-card-expiry-date",
            name: "labor_card_expiry_date",
            dateFormat: "YYYY/MM/DD",
            isConditionalRender: true
        },
        {
            id: 29,
            control: "input",
            as: "",
            label: "labor_card_number",
            name: "labor_card_number",
            isConditionalRender: true
        },
        {
            id: 30,
            control: "input",
            as: "",
            label: "bank-name",
            name: "bank_name",
            isConditionalRender: true
        },
        {
            id: 31,
            control: "input",
            as: "",
            label: "account-holder-name",
            name: "account_holder_name",
            isConditionalRender: true
        },
        {
            id: 32,
            control: "input",
            as: "",
            label: "account-number",
            name: "account_number",
            isConditionalRender: true
        },
        {
            id: 33,
            control: "input",
            as: "",
            label: "iban",
            name: "iban",
            isConditionalRender: true
        },
        {
            id: 34,
            control: "input",
            as: "",
            label: "swift code",
            name: "swift_code",
            isConditionalRender: true
        },
        {
            id: 35,
            control: "date-picker",
            label: "OfficialDateOfEmployment",
            name: "official_date_of_employment",
            dateFormat: "YYYY/MM/DD"
        },
        {
            id: 36,
            control: "input",
            as: "",
            label: "employer",
            name: "employer",
        },

        {
            id: 37,
            control: "select",
            label: "Manager",
            name: "manager_id",
            arrayOfOptions: mangerOptions
        },
        {
            id: 38,
            control: "multi-select",
            label: "roles",
            name: "role",
            multiSelectOptions: roles,
            displayValue: "name"
        },
        {
            id: 39,
            control: "input",
            as: "",
            label: "password",
            name: "password",
            type: "password"
        },
    ]
    return { inputs }
}

export default InputsArray