import schema from "../../Utils/Validations/Users/EditSchema";
import { useTranslation } from "react-i18next";
import { FormikContainer } from "../Forms";
import { InputsArray } from "../../Assets/Data/Dashboard/Users";
import { FormikHelpers, FormikValues } from "formik";
import { FC, useContext } from "react";
import useQueryPost from "../../Custom Hooks/useQueryPost";
import UserService from "../../Services/UserService";
import useQueryGet from "../../Custom Hooks/useQueryGet";
import { TableContext } from "../../Context/TableContext";

type AddModalProps = {
    roles: any,
    mangerOptions: any[],
    handleClose: () => void
}


const EditModal: FC<AddModalProps> = ({ roles, mangerOptions, handleClose }) => {
    const { t } = useTranslation()
    const { recordId } = useContext(TableContext)
    const [user] = useQueryGet(() => UserService.getUserById(recordId), ["get-single-user", recordId])
    const [handleSubmit,] = useQueryPost(UserService.updateUser, ["edit-user"], {
        success: t("edited-succssefully")
    }, ["get-all-users"], () => {
        handleClose()
    })


    const onSubmit = (values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        handleSubmit({ ...values, id: recordId })
            .then((_) => {
                onSubmitProps.setSubmitting(false)
            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }
    const initialValues = {
        image: "",
        type: user?.data?.data?.type || "",
        email: user?.data?.data?.email || "",
        name: user?.data?.data?.name || "",
        date_of_birth: user?.data?.data?.date_of_birth || "",
        mobile: user?.data?.data?.mobile || "",
        nationality: user?.data?.data?.nationality || "",
        home_country: user?.data?.data?.home_country || "",
        address_home: user?.data?.data?.address_home || "",
        employer: user?.data?.data?.employer || "",
        official_date_of_employment: user?.data?.data?.official_date_of_employment || "",
        blood_type: user?.data?.data?.blood_type || "",
        work_description: user?.data?.data?.work_description || "",
        position: user?.data?.data?.position || "",
        department_id: 1,
        university_certificate: user?.data?.data?.university_certificate || "",
        base_salary: user?.data?.data?.base_salary || "",
        account_number: user?.data?.data?.account_number || "",
        swift_code: user?.data?.data?.swift_code || "",
        manager_id: user?.data?.data?.manager_id || "",
        account_holder_name: user?.data?.data?.account_holder_name || "",
        bank_name: user?.data?.data?.bank_name || "",
        labor_card_issue_date: user?.data?.data?.labor_card_issue_date || "",
        labor_card_expiry_date: user?.data?.data?.labor_card_expiry_date || "",
        visa_id_number: user?.data?.data?.visa_id_number || "",
        visa_place_of_issue: user?.data?.data?.visa_place_of_issue || "",
        visa_expiry_date: user?.data?.data?.visa_expiry_date || "",
        visa_type: user?.data?.data?.visa_type || "",
        passport_place_of_issue: user?.data?.data?.passport_place_of_issue || "",
        passport_expiry_date: user?.data?.data?.passport_expiry_date || "",
        passport_number: user?.data?.data?.passport_number || "",
        emergency_contact_relation: user?.data?.data?.emergency_contact_relation || "",
        emergency_contact_person: user?.data?.data?.emergency_contact_person || "",
        emergency_contact_mobile: user?.data?.data?.emergency_contact_mobile || "",
        address_uae: user?.data?.data?.address_uae || "",
        iban: user?.data?.data?.iban || ""
    }
    return (
        <FormikContainer
            onSubmit={onSubmit}
            initialValues={initialValues}
            schema={schema}
            className=""
            btnText={t("edit")}
            arrayOfInputs={InputsArray(roles, mangerOptions).inputs.slice(0, -2)}
            OptionalConfig={{
                isNeedCheck: true,
                checkName: "type",
                checkValue: "On-site"
            }}
            optionalFileds={["address_uae",
                "emergency_contact_person",
                "emergency_contact_mobile",
                "emergency_contact_relation",
                "passport_number",
                "passport_expiry_date",
                "passport_place_of_issue",
                "visa_type",
                "visa_expiry_date",
                "visa_place_of_issue",
                "visa_id_number",
                "labor_card_issue_date",
                "labor_card_expiry_date",
                "labor_card_number",
                "bank_name",
                "account_holder_name",
                "account_number",
                "iban",
                "swift_code"]}
            isEnableReinit={true}
        />
    )
}

export default EditModal