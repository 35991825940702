import { useTranslation } from 'react-i18next'
import { AddModal, EditModal, ResetPasswordModal } from '../../../../Components/Users'
import useQueryGet from '../../../../Custom Hooks/useQueryGet'
import RolesService from '../../../../Services/RolesService'
import { DeleteModal } from '../../../../Components'
import UserService from '../../../../Services/UserService'
import { useContext } from 'react'
import { TableContext } from '../../../../Context/TableContext'

const UsersModals = (users: any[], handleClose: (name: string) => void) => {
    const { t } = useTranslation()
    const { record } = useContext(TableContext)
    const [roles,] = useQueryGet(RolesService.getAll, ["get-roles"])
    const mangerOptions = users?.map(user => {
        return {
            id: user.id,
            title: user.name,
            value: user.id
        }
    })
    const modals = [
        {
            id: 1,
            name: "addUser",
            title: t("add"),
            children: <AddModal
                roles={roles?.data?.data}
                mangerOptions={mangerOptions}
                handleClose={() => handleClose("addUser")}
            />
        },
        {
            id: 2,
            name: "editUser",
            title: t("edit"),
            children: <EditModal
                roles={roles?.data?.data}
                mangerOptions={mangerOptions}
                handleClose={() => handleClose("editUser")}
            />
        },
        {
            id: 3,
            name: "resetPassword",
            title: t("ResetPassword"),
            children: <ResetPasswordModal
                handleClose={() => handleClose("addUser")}
            />
        },
        {
            id: 4,
            name: "Delete",
            title: record?.is_active == 1 ? t("deactivate") : t("activate"),
            children: <DeleteModal
                btnText={record?.is_active == 1 ? t("deactivate") : t("activate")}
                endPointConfig={{
                    invalidate: "get-all-users",
                    queryKey: ["delete-decision"],
                    endPoint: UserService.deactiveUser
                }}
                handleClose={() => handleClose("Delete")} />
        }
    ]

    return { modals }
}

export default UsersModals