import { useTranslation } from "react-i18next"
import { DeleteModal } from "../../../../../Components"
import UserService from "../../../../../Services/UserService"
import { AddModal } from "../../../../../Components/ProfileMangement/FileMangement"


const FileMangementModals = (handleClose: (name: string) => void, id: any) => {
    const { t } = useTranslation()
    const modals = [
        {
            id: 1,
            name: "addFile",
            title: t("add"),
            children: <AddModal handleClose={handleClose} id={id} />
        },
        {
            id: 2,
            name: "Delete",
            title: t("Delete"),
            children: <DeleteModal
                btnText={t("Delete")}
                endPointConfig={{
                    invalidate: "get-all-files",
                    queryKey: ["delete-file"],
                    endPoint: UserService.deleteFileById
                }}
                handleClose={() => handleClose("Delete")} />
        }
    ]

    return { modals }
}

export default FileMangementModals