import { FC, useContext } from "react"
import { FormikContainer } from "../Forms"
import { InputsArray } from "../../Assets/Data/Dashboard/LeavesSuggestion"
import schema from "../../Utils/Validations/LeavesSuggestion/schema"
import { FormikHelpers, FormikValues } from "formik"
import { useTranslation } from "react-i18next"
import useQueryPost from "../../Custom Hooks/useQueryPost"
import LeavesSuggestionService from "../../Services/LeavesSuggestionService"
import { TableContext } from "../../Context/TableContext"
import useQueryGet from "../../Custom Hooks/useQueryGet"

type EditModalProps = {
    handleClose: (name: string) => void
}
const EditModal: FC<EditModalProps> = ({ handleClose }) => {
    const { t } = useTranslation()
    const { recordId } = useContext(TableContext)
    const [leavesSuggestion] = useQueryGet(() => LeavesSuggestionService.show(recordId), ["get-leave-suggestion", recordId])
    const [handleEdit] = useQueryPost(LeavesSuggestionService.update, ["edit-leave-suggestion"], {
        success: t("edited-succssefully")
    }, ["get-all-leaves-suggestion"], () => {
        handleClose("editLeavesSuggestion")
    })
    const initialValues = {
        start_date: leavesSuggestion?.data?.data?.start_date || "",
        end_date: leavesSuggestion?.data?.data?.end_date || "",
    }
    const onSubmit = (values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        handleEdit({ ...values, id: recordId })
            .then((_) => {
                onSubmitProps.setSubmitting(false)
            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }
    return (
        <FormikContainer
            className=""
            arrayOfInputs={InputsArray}
            schema={schema}
            initialValues={initialValues}
            onSubmit={onSubmit}
            btnText={t("edit")}
            isEnableReinit={true}
        />
    )
}

export default EditModal