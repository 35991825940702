import { useState } from "react"

const useModals = (config: any) => {
    const [show, setShow] = useState(config)
    const handleShow = (name: string) => setShow((prev: any) => {
        return {
            ...prev,
            [name]: !show[name]
        }
    }
    )
    const handleClose = (name: string) => setShow((prev: any) => {
        return {
            ...prev,
            [name]: !show[name]
        }
    }
    )
    return { show, handleShow, handleClose }
}

export default useModals