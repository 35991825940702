import "./ExportSalary.css"
import { ToastContainer } from "react-toastify"
import { ExportSalaryTable, SearchTable } from "../../../Sections/ExportSalary"

const ExportSalary = () => {
    return (
        <>
            <ExportSalaryTable />
            <SearchTable />
            <ToastContainer />
        </>
    )
}

export default ExportSalary