import EmployerOfTheMonthService from "../../../../Services/EmployerOfTheMonthService"
import { useTranslation } from "react-i18next"
import { ShowModal } from "../../../../Components/HrDashboard"
import { DeleteModal } from "../../../../Components"

const EmployerOfTheMonthModals = (handleClose: (name: string) => void) => {
    const { t } = useTranslation()
    const modals = [
        {
            id: 1,
            name: "ShowEmployer",
            title: t("show"),
            children: <ShowModal />
        },
        {
            id: 2,
            name: "Delete",
            title: t("Delete"),
            children: <DeleteModal
                btnText={t("Delete")}
                handleClose={() => handleClose("Delete")}
                endPointConfig={{
                    invalidate: "get-all-emolyer-of-the-month",
                    queryKey: ["delete-employer-of-the-month"],
                    endPoint: EmployerOfTheMonthService.delete
                }} />
        }
    ]

    return { modals }
}

export default EmployerOfTheMonthModals