import { useEffect } from "react"
import { DropDownType } from "../../../../Models/DropDownType"
import SaudiFlag from "../../../Images/saudi-arabia.svg"
import UnitedStatesFlag from "../../../Images/united-states.svg"
import { useTranslation } from "react-i18next"
import i18nn from "../../../../i18n"
const LangData = () => {
    const { i18n } = useTranslation()
    const changeLanguages = (lang: string) => {
        localStorage.setItem("lang", lang)
        window.location.reload()
    }
    useEffect(() => {
        document.body.dir = i18n.language == "en" ? "ltr" : "rtl"
        const lng: string = localStorage.getItem("lang") || "en"
        document.body.classList.add(lng)
        i18nn.changeLanguage(lng)
    }, [i18n.language])

    const items: DropDownType[] = [
        {
            id: 1,
            className: "languages",
            children: <><span><img src={SaudiFlag} alt="" /></span>العربية</>,
            onClick: () => changeLanguages("ar")
        },
        {
            id: 2,
            className: "languages",
            children: <><span><img src={UnitedStatesFlag} alt="" /></span>English</>,
            onClick: () => changeLanguages("en")
        }
    ]

    return { items }
}

export default LangData