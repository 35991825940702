import { ToastContainer } from "react-toastify"
import { BoxTitle, BoxWithShadow, DynamicTable, MainBtn, ModalComponent } from "../../../Components"
import "./SpecialEvents.css"
import { useTranslation } from "react-i18next"
import useGet from "../../../Custom Hooks/useGet"
import SpecialEventsService from "../../../Services/SpecialEventsService"
import useModals from "../../../Custom Hooks/useModals"
import { SpecialEventsCols, SpecialEventsModals } from "../../../Assets/Data/Dashboard/SpecialEvents"
import useQueryGet from "../../../Custom Hooks/useQueryGet"
import { useState } from "react"

const SpecialEvents = () => {
    const { t } = useTranslation()
    const [page, setPage] = useState(1)
    const [dataSource, , , loading] = useQueryGet(() => SpecialEventsService.getAll(page), ["get-special-events", page])
    const { show, handleShow, handleClose } = useModals({
        addSpecialEvent: false,
        editSpecialEvent: false,
        showSpecialEvent: false,
        Delete: false

    })
    const { modals } = SpecialEventsModals(handleClose)
    return (
        <>

            <BoxWithShadow additionalClass={`container-fluid users mb-4`}>
                <div className="flex-between-start">
                    <BoxTitle title={t("special-events")} />
                    <MainBtn title={t("add")} onClick={() => handleShow("addSpecialEvent")} />
                </div>
                <DynamicTable
                    loading={loading}
                    dataSource={dataSource?.data?.data?.data}
                    columns={SpecialEventsCols(handleShow).columns}
                    pagination={{
                        pageSize: dataSource?.data?.data?.per_page,
                        total: dataSource?.data?.data?.total,
                        onChange: (page) => setPage(page)

                    }}
                />

                <ModalComponent arrayOfModals={modals} handleClose={handleClose} show={show} />
            </BoxWithShadow>
            <ToastContainer />
        </>
    )
}

export default SpecialEvents