import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import "./PageTitle.css"


const PageTitle = () => {
    const [pageName, setPageName] = useState<string>("")
    const location = useLocation()
    const { i18n, t } = useTranslation()
    useEffect(() => {
        if (location.pathname === "/dashboard" || location.pathname === "/Dashboard") {
            setPageName("Main-Page")
        }
        else {
            setPageName(location.pathname.split("/")[2])
        }
    }, [location.pathname])
    return (
        <h2>{t(pageName)}</h2>
    )
}

export default PageTitle