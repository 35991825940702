import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { TableContext } from "../../Context/TableContext"
import useQueryGet from "../../Custom Hooks/useQueryGet"
import OutdoorTasksService from "../../Services/OutdoorTasksService"


const ShowModal = () => {
    const { t } = useTranslation()
    const { recordId } = useContext(TableContext)
    const [outdoorTask] = useQueryGet(() => OutdoorTasksService.show(recordId), ["get-single-outdoor-task", recordId])
    return (
        <>
            <div className="flex-start-center-row mb-3 show-box show-box-outdoor-task">
                <label className="mb-0 mx-2">{t("name")} :  </label>
                <p>{outdoorTask?.data?.data?.user?.name}</p>
            </div>
            <div className="flex-start-center-row mb-3 show-box show-box-outdoor-task">
                <label className="mb-0 mx-2">{t("why")}  :  </label>
                <p>{outdoorTask?.data?.data?.why}</p>
            </div>
            <div className="flex-start-center-row mb-3 show-box show-box-outdoor-task">
                <label className="mb-0 mx-2">{t("where_to_go")} :  </label>
                <p>{outdoorTask?.data?.data?.where_to_go}</p>
            </div>
            <div className="flex-start-center-row mb-3 show-box show-box-outdoor-task">
                <label className="mb-0 mx-2">{t("start")} :  </label>
                <p>{outdoorTask?.data?.data?.start}</p>
            </div>
            <div className="flex-start-center-row mb-3 show-box show-box-outdoor-task">
                <label className="mb-0 mx-2">{t("end")} :  </label>
                <p>{outdoorTask?.data?.data?.end}</p>
            </div>
            <div className="flex-start-center-row mb-3 show-box show-box-outdoor-task">
                <label className="mb-0 mx-2">{t("date")} :  </label>
                <p>{outdoorTask?.data?.data?.date}</p>
            </div>
        </>
    )
}

export default ShowModal