import { useTranslation } from "react-i18next"
import { AddModal, EditModal, ShowModal } from "../../../../Components/MainPage"
import { DeleteModal } from "../../../../Components"
import AdministrativeDecisionsService from "../../../../Services/AdministrativeDecisionsService"



const MainPageModals = (handleClose: (name: string) => void, handleShow: (name: string) => void) => {
    const { t } = useTranslation()
    const modals = [
        {
            id: 1,
            name: "Decisions",
            title: t("add"),
            children: <AddModal handleClose={() => handleClose("Decisions")} />
        },
        {
            id: 2,
            name: "EditDecisions",
            title: t("edit"),
            children: <EditModal handleClose={() => handleClose("EditDecisions")} />
        },
        {
            id: 3,
            name: "ShowDecision",
            title: t("show"),
            children: <ShowModal />
        },
        {
            id: 4,
            name: "Delete",
            title: t("Delete"),
            children: <DeleteModal
                btnText={t("Delete")}
                endPointConfig={{
                    invalidate: "administrative-decisions",
                    queryKey: ["delete-decision"],
                    endPoint: AdministrativeDecisionsService.deleteDecision
                }}
                handleClose={() => handleClose("Delete")} />
        }
    ]

    return { modals }
}

export default MainPageModals