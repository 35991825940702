import { BoxTitle, BoxWithShadow } from "../../../../Components"
import "./ChangePassword.css"
import { useTranslation } from "react-i18next"
import ChangePasswordSchema from "../../../../Utils/Validations/ProfileMangement/ChangePassword/ChangePasswordSchema"
import { ToastContainer } from "react-toastify"
import UserService from "../../../../Services/UserService"
import { FormikContainer } from "../../../../Components/Forms"
import { InputsArray } from "../../../../Assets/Data/Dashboard/ProfileMangement/ChangePassword"
import useQueryPost from "../../../../Custom Hooks/useQueryPost"
import { FormikHelpers, FormikValues } from "formik"
const initialValues = {
    old_password: "",
    password: "",
    password_confirmation: ""
}
const ChangePassword = () => {
    const { t } = useTranslation()
    const [handleSubmit,] = useQueryPost(UserService.ChangePassword, ["change-passowrd"], {
        success: t("ChangePasswordSuccessfully"),
    })
    const OnSubmit = (values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        handleSubmit(values)
            .then((_) => {
                onSubmitProps.setSubmitting(false)
            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }
    return (
        <>
            <BoxWithShadow additionalClass="profile-details container-fluid change-password-con">
                <div className="flex-between">
                    <BoxTitle title={t("ChangePassword")} />
                </div>

                <FormikContainer
                    arrayOfInputs={InputsArray}
                    schema={ChangePasswordSchema}
                    className=""
                    onSubmit={OnSubmit}
                    initialValues={initialValues}
                    btnText={t("ChangePassword")}
                />
                <ToastContainer />
            </BoxWithShadow>
        </>
    )
}

export default ChangePassword