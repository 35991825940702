import { InputsArrayTypes } from "../../../../Models/InputsArrayTypes";




const LeavesInputs = (t: (key: string) => string) => {
    const inputsArray: InputsArrayTypes[] = [
        {
            id: 1,
            control: "date-picker",
            label: "start",
            name: "start_date",
            dateFormat: "YYYY/MM/DD"
        },
        {
            id: 2,
            control: "date-picker",
            label: "end",
            name: "end_date",
            dateFormat: "YYYY/MM/DD"
        },
        {
            id: 3,
            control: "date-picker",
            label: "BackToWorkDate",
            name: "back_to_work_date",
            dateFormat: "YYYY/MM/DD"
        },
        {
            id: 4,
            control: "select",
            label: "type",
            name: "type",
            arrayOfOptions: [
                {
                    id: 1,
                    title: t("sick-leave"),
                    value: "1"
                },
                {
                    id: 2,
                    title: t("administrative-leave"),
                    value: "2"
                },
                {
                    id: 3,
                    title: t("leave-without-pay"),
                    value: "3"
                },
            ]
        },
        {
            id: 5,
            control: "Report",
            label: "Medical-Report",
            name: "medical_report",
        },
        {
            id: 6,
            control: "input",
            as: "",
            label: "Position",
            name: "position",
        },
    ]
    return { inputsArray }
}

export default LeavesInputs