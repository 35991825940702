import { InputsArrayTypes } from "../../../../../Models/InputsArrayTypes";

const InputsArray: InputsArrayTypes[] = [
    {
        id: 1,
        control: "input",
        as: "",
        label: "name",
        name: "name",
        isTwoCols: true
    },
    {
        id: 2,
        control: "input",
        as: "",
        label: "email",
        name: "email",
        isTwoCols: true
    },
    {
        id: 3,
        control: "input",
        as: "",
        label: "employer",
        name: "employer",
        isTwoCols: true
    },
    {
        id: 4,
        control: "date-picker",
        label: "DateOfBirth",
        name: "date_of_birth",
        dateFormat: "YYYY/MM/DD",
        isTwoCols: true
    },
    {
        id: 5,
        control: "input",
        as: "",
        label: "mobile",
        name: "mobile",
        isTwoCols: true
    },
    {
        id: 6,
        control: "input",
        as: "",
        label: "visa-id-number",
        name: "visa_id_number",
        isTwoCols: true
    },
    {
        id: 7,
        control: "date-picker",
        label: "visa-expiry-date",
        name: "visa_expiry_date",
        dateFormat: "YYYY/MM/DD",
        isTwoCols: true
    },
    {
        id: 8,
        control: "date-picker",
        label: "labor-card-issue-date",
        name: "labor_card_issue_date",
        dateFormat: "YYYY/MM/DD",
        isTwoCols: true
    },
    {
        id: 9,
        control: "date-picker",
        label: "labor-card-expiry-date",
        name: "labor_card_expiry_date",
        dateFormat: "YYYY/MM/DD",
        isTwoCols: true
    },
    {
        id: 10,
        control: "date-picker",
        label: "OfficialDateOfEmployment",
        name: "official_date_of_employment",
        dateFormat: "YYYY/MM/DD",
        isTwoCols: true
    },
    {
        id: 11,
        control: "select",
        label: "BloodType",
        name: "blood_type",
        arrayOfOptions: [
            {
                id: 1,
                title: "A+",
                value: "A+"
            },
            {
                id: 2,
                title: "A-",
                value: "A-"
            },
            {
                id: 3,
                title: "B+",
                value: "B+"
            },
            {
                id: 4,
                title: "B-",
                value: "B-"
            },
            {
                id: 5,
                title: "O+",
                value: "O+"
            },
            {
                id: 6,
                title: "O-",
                value: "O-"
            },
            {
                id: 7,
                title: "AB+",
                value: "AB+"
            },
            {
                id: 8,
                title: "AB-",
                value: "AB-"
            }
        ],
        isTwoCols: true
    },
    {
        id: 12,
        control: "input",
        as: "",
        label: "BaseSalary",
        name: "base_salary",
        isTwoCols: true
    },
]
export default InputsArray