import { ErrorMessage, Field } from "formik";
import "./DatePickerHour.css"
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import TextError from "../../TextError/TextError";
import moment from "moment";
import DatePicker from "react-datepicker";
type DatePickerHourProps = {
    label: string,
    name: string
}
const DatePickerHour: FC<DatePickerHourProps> = ({ label, name }) => {
    const { t } = useTranslation()
    const [date, setDate] = useState<any>();
    return (
        <div className="form-control dynamic-input">
            <label htmlFor={label} className="form-label">{t(label)}</label>
            <Field name={name} id={label} className="form-control" >
                {({ field, form }: any) => (
                    <DatePicker
                        className='form-control'
                        selected={date}
                        onChange={(val: any) => {
                            setDate(val)
                            if (val) {
                                const formattedDate = moment(val).format("HH:mm");
                                form.setFieldValue(name, formattedDate);
                            }
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="HH:mm"
                    />
                )}
            </Field>
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}

export default DatePickerHour