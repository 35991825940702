import { useTranslation } from "react-i18next";
import { DropDownMenu } from "../../../../Components";
import { useContext } from "react";
import { TableContext } from "../../../../Context/TableContext";
import ActionsDropdown from "./ActionsDropdown";

const SpecialEventsCols = (handleShow: (name: string) => void) => {
    const { t } = useTranslation()
    const { setRecordId } = useContext(TableContext)
    const columns = [
        {
            title: t("Title"),
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: t("start"),
            dataIndex: 'start',
            key: 'start',
        },
        {
            title: t("end"),
            dataIndex: 'end',
            key: 'end',
        },

        {
            title: t("actions"),
            dataIndex: 'id',
            key: 'id',
            render: (id: number) => <>
                <DropDownMenu
                    className="action-table"
                    arrayOfItems={ActionsDropdown(id, handleShow, t, setRecordId).items}
                    children={t("actions")} />
            </>
        },
    ]

    return { columns }

}

export default SpecialEventsCols