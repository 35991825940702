import { FC, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import useQueryPost from "../../Custom Hooks/useQueryPost"
import { FormikHelpers, FormikValues } from "formik"
import { TableContext } from "../../Context/TableContext"
import useQueryGet from "../../Custom Hooks/useQueryGet"
import { FormikContainer } from "../Forms"
import OutdoorTasksService from "../../Services/OutdoorTasksService"
import { UserContext } from "../../Context/UserContext"
import { InputsArray } from "../../Assets/Data/Dashboard/OutdoorTasks"
import schema from "../../Utils/Validations/OutdoorTasks/schema"

type EditModalProps = {
    handleClose: (name: string) => void
}

const EditModal: FC<EditModalProps> = ({ handleClose }) => {
    const [start, setDate] = useState(new Date());
    const { t } = useTranslation()
    const { userId } = useContext(UserContext)
    const { recordId } = useContext(TableContext)
    const [handleSubmit] = useQueryPost(OutdoorTasksService.edit,
        ["edit-outdoor-task"],
        {
            success: t("edited-succssefully"),
        }, ["get-all-outdoor-tasks"], () => {
            handleClose("editOutdoorTask")
        }
    )
    const [outdoorTask] = useQueryGet(() => OutdoorTasksService.show(recordId), ["get-single-outdoor-task", recordId])

    const initialValues = {
        date: outdoorTask?.data?.data?.date || "",
        where_to_go: outdoorTask?.data?.data?.where_to_go || "",
        why: outdoorTask?.data?.data?.why || "",
        start: start,
        end: start,


    }
    const onSubmit = (values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        handleSubmit({ ...values, user_id: userId, id: recordId })
            .then((_) => {
                onSubmitProps.setSubmitting(false)

            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }
    return (
        <>
            <FormikContainer
                initialValues={initialValues}
                onSubmit={onSubmit}
                arrayOfInputs={InputsArray}
                className=" "
                btnText={t("edit")}
                schema={schema}
                isEnableReinit={true}
            />
        </>
    )
}

export default EditModal