import { useTranslation } from "react-i18next";
import { DropDownMenu } from "../../../../Components";
import ActionsDropdown from "./ActionsDropdown";
import { useContext } from "react";
import { TableContext } from "../../../../Context/TableContext";

const DepartmentsCols = (handleShow: (name: string) => void) => {
    const { t } = useTranslation()
    const { setRecordId, setRecord } = useContext(TableContext)
    const cols = [
        {
            title: "id",
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: t("name"),
            dataIndex: 'name',
            key: 'name',
        },


        {
            title: t("actions"),
            dataIndex: 'id',
            key: 'id',
            render: (id: number, record: any) => <>
                <DropDownMenu
                    className="action-table"
                    arrayOfItems={ActionsDropdown(id, handleShow, t, setRecordId, setRecord, record).items}
                    children={t("actions")}
                />
            </>
        },
    ];

    return { cols }

}

export default DepartmentsCols