import { FC } from "react"
import FormikControl from "./FormikControl/FormikControl"
import { FormikProps, FormikValues } from "formik"

type IsVisibleInputProps = {
    OptionalConfig: {
        isNeedCheck: boolean,
        checkName: string,
        checkValue: string
    },
    input: any,
    formik: FormikProps<FormikValues>,
    optionalFileds?: string[]
}

const IsVisibleInput: FC<IsVisibleInputProps> = ({ OptionalConfig, input, formik, optionalFileds }) => {
    if (formik.values[OptionalConfig?.checkName] == OptionalConfig?.checkValue && optionalFileds?.includes(input.name)) {
        return <FormikControl
            key={input.id}
            control={input.control}
            name={input.name}
            label={input.label}
            as={input.as}
            arrayOfOptions={input.arrayOfOptions}
            isTwoCols={input.isTwoCols}
            type={input.type}
            value={formik.values[input.name]}
            dateFormat={input.dateFormat}
            multiSelectOptions={input.multiSelectOptions}
            displayValue={input.displayValue}
            formik={formik}
        />
    }
    else if (!optionalFileds?.includes(input.name)) {
        return <FormikControl
            key={input.id}
            control={input.control}
            name={input.name}
            label={input.label}
            as={input.as}
            arrayOfOptions={input.arrayOfOptions}
            isTwoCols={input.isTwoCols}
            type={input.type}
            value={formik.values[input.name]}
            dateFormat={input.dateFormat}
            multiSelectOptions={input.multiSelectOptions}
            displayValue={input.displayValue}
            formik={formik}
        />
    }
    else {
        return null
    }
}

export default IsVisibleInput