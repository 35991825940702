import { useTranslation } from 'react-i18next'
import { AddOutdoorModal, ShowOutdoorTaskModal } from '../../../../Components/Calendar/OutdoorTasks'
import { AddLeaveSuggestionModal, ShowLeavesSuggestionModal } from '../../../../Components/Calendar/LeaveSuggestion'
import { AddLeaveModal, ShowLeaveModal } from '../../../../Components/Calendar/Leaves'
import { ShowSpecialEventModal } from '../../../../Components/Calendar/SpecialEvents'

const CalendarModals = (handleClose: (name: string) => void) => {
    const { t } = useTranslation()
    const modals = [
        {
            id: 1,
            name: "addOutdoorTask",
            title: t("add"),
            children: <AddOutdoorModal handleClose={() => handleClose("addOutdoorTask")} />
        },
        {
            id: 2,
            name: "addLeaveSuggestion",
            title: t("add"),
            children: <AddLeaveSuggestionModal handleClose={() => handleClose("addLeaveSuggestion")} />
        },
        {
            id: 3,
            name: "addLeave",
            title: t("add"),
            children: <AddLeaveModal handleClose={() => handleClose("addLeave")} />
        },
        {
            id: 4,
            name: "showLeave",
            title: t("show"),
            children: <ShowLeaveModal />
        },
        {
            id: 5,
            name: "showSpecialEvent",
            title: t("show"),
            children: <ShowSpecialEventModal />
        },
        {
            id: 6,
            name: "showLeavesSuggestion",
            title: t("show"),
            children: <ShowLeavesSuggestionModal />
        },
        {
            id: 7,
            name: "showOutdoorTask",
            title: t("show"),
            children: <ShowOutdoorTaskModal />
        },

    ]

    return { modals }
}

export default CalendarModals