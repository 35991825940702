import { useTranslation } from 'react-i18next'
import { AddModal, EditModal, ShowModal } from '../../../../Components/SpecialEvents'
import { DeleteModal } from '../../../../Components'
import SpecialEventsService from '../../../../Services/SpecialEventsService'

const SpecialEventsModals = (handleClose: (name: string) => void) => {
    const { t } = useTranslation()
    const modals = [
        {
            id: 1,
            name: "showSpecialEvent",
            title: t("show"),
            children: <ShowModal />
        },
        {
            id: 2,
            name: "addSpecialEvent",
            title: t("AddSpecialEvent"),
            children: <AddModal handleClose={handleClose} />
        },
        {
            id: 3,
            name: "editSpecialEvent",
            title: t("EditSpecialEvent"),
            children: <EditModal handleClose={handleClose} />
        },
        {
            id: 4,
            name: "Delete",
            title: t("Delete"),
            children: <DeleteModal
                btnText={t("Delete")}
                endPointConfig={{
                    invalidate: "get-special-events",
                    queryKey: ["delete-special-events"],
                    endPoint: SpecialEventsService.delete
                }}
                handleClose={() => handleClose("Delete")} />
        }
    ]

    return { modals }
}

export default SpecialEventsModals