import { FC } from "react"
import { BoxTitle, BoxWithShadow, DynamicTable, MainBtn } from "../../../Components"
import { useTranslation } from "react-i18next"
import { BonusTableCols } from "../../../Assets/Data/Dashboard/ProfileMangement/Salary"

type BonusTableProps = {
    handleShow: (name: string) => void,
    loading: boolean,
    bonuses: any
}
const BonusTable: FC<BonusTableProps> = ({ handleShow, loading, bonuses }) => {
    const { t } = useTranslation()
    return (
        <div className="col">
            <BoxWithShadow additionalClass="mb-3 users">
                <div className="flex-between-start">
                    <BoxTitle title={t("TotalBonus")} />
                    <MainBtn title={t("add")} onClick={() => handleShow("addBonus")} />
                </div>
                <DynamicTable
                    loading={loading}
                    dataSource={bonuses}
                    columns={BonusTableCols(t, handleShow).cols}
                    pagination={false}
                />
            </BoxWithShadow>
        </div>
    )
}

export default BonusTable