import { AxiosResponse } from "axios";
import { useQuery } from "react-query"
type EndpointFunction = (data: any) => Promise<AxiosResponse<any, any>>;
const useQueryGet = (endPoint: EndpointFunction, queryKey: any[], onSuccess?: (res: AxiosResponse) => void): [AxiosResponse<any, any> | undefined, boolean, any, boolean] => {
    const { data, isLoading, error, isFetching } = useQuery({
        queryKey: [...queryKey],
        queryFn: endPoint,
        staleTime: Infinity,
        onSuccess: (res) => {
            if (onSuccess) {
                onSuccess(res)
            }
        }
    })
    return [data, isFetching, error, isLoading]
}
export default useQueryGet