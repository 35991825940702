import { useTranslation } from "react-i18next"
import { BoxTitle, BoxWithShadow, DynamicTable, MainBtn } from "../../../Components"
import "./ExportSalaryTable.css"
import useQueryGet from "../../../Custom Hooks/useQueryGet"
import PayrollService from "../../../Services/PayrollService"
import { ExportSalaryTableCols } from "../../../Assets/Data/Dashboard/ExportSalary"
import useQueryPost from "../../../Custom Hooks/useQueryPost"

const ExportSalaryTable = () => {
    const { t } = useTranslation()
    const [dataSource, , , loading] = useQueryGet(PayrollService.get, ["get-data-to-export"])
    const [handleExport] = useQueryPost(PayrollService.store, ["export-all-salary"], {
        success: t("exported-successfully"),
    }, ["get-data-to-export"])
    return (
        <BoxWithShadow additionalClass={`container-fluid users mb-4`}>
            <div className="flex-between-start">
                <BoxTitle title={t("Export-Salary")} />
                <MainBtn title={t("Export")} onClick={() => handleExport("export")} />
            </div>
            <DynamicTable
                loading={loading}
                dataSource={dataSource?.data?.data}
                columns={ExportSalaryTableCols().columns}
                pagination={false}
            />

        </BoxWithShadow>
    )
}

export default ExportSalaryTable