import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { TableContext } from "../../Context/TableContext"
import useQueryGet from "../../Custom Hooks/useQueryGet"
import LeavesSuggestionService from "../../Services/LeavesSuggestionService"


const ShowModal = () => {
    const { t } = useTranslation()
    const { recordId } = useContext(TableContext)
    const [leavesSuggestion] = useQueryGet(() => LeavesSuggestionService.show(recordId), ["get-leave-suggestion", recordId])
    return (
        <>
            <div className="flex-start-center-row mb-3 show-box show-box-leave">
                <label className="mb-0 mx-2">{t("name")} :  </label>
                <p>{leavesSuggestion?.data?.data?.user?.name}</p>
            </div>
            <div className="flex-start-center-row mb-3 show-box show-box-leave">
                <label className="mb-0 mx-2">{t("direct_manager")}:  </label>
                <p>{leavesSuggestion?.data?.data?.approved_by_direct_manager == 1 ? t("approved") : t("unpproved")}</p>
            </div>
            <div className="flex-start-center-row mb-3 show-box show-box-leave">
                <label className="mb-0 mx-2">{t("general_manager")} :  </label>
                <p>{leavesSuggestion?.data?.data?.approved_by_general_manager == 1 ? t("approved") : t("unpproved")}</p>
            </div>
            <div className="flex-start-center-row mb-3 show-box show-box-leave">
                <label className="mb-0 mx-2">{t("hr")}  :  </label>
                <p>{leavesSuggestion?.data?.data?.approved_by_hr == 1 ? t("approved") : t("unpproved")}</p>
            </div>
            <div className="flex-start-center-row mb-3 show-box show-box-leave ">
                <label className="mb-0 mx-2">{t("start")} : </label>
                <p>{leavesSuggestion?.data?.data?.start_date}</p>
            </div>
            <div className="flex-start-center-row mb-3 show-box show-box-leave">
                <label className="mb-0 mx-2">{t("end")} : </label>
                <p>{leavesSuggestion?.data?.data?.end_date}</p>
            </div>


        </>
    )
}

export default ShowModal