import { useTranslation } from "react-i18next"
import { BoxTitle, BoxWithShadow, DynamicTable, ModalComponent } from "../../../Components"
import "./EmployerOfTheMonthBox.css"
import useQueryGet from "../../../Custom Hooks/useQueryGet"
import EmployerOfTheMonthService from "../../../Services/EmployerOfTheMonthService"
import { EmployerOfTheMonthCols } from "../../../Assets/Data/Dashboard/HrDashboard"
import useModals from "../../../Custom Hooks/useModals"
import EmployerOfTheMonthModals from "../../../Assets/Data/Dashboard/HrDashboard/EmployerOfTheMonthModals"

const EmployerOfTheMonthBox = () => {
    const { t } = useTranslation()
    const [employies, , , loading] = useQueryGet(EmployerOfTheMonthService.getAll, ["get-all-emolyer-of-the-month"])
    const { show, handleShow, handleClose } = useModals(
        {
            ShowEmployer: false,
            Delete: false
        }
    )
    const { cols } = EmployerOfTheMonthCols(handleShow)
    const { modals } = EmployerOfTheMonthModals(handleClose)
    return (
        <>
            <BoxWithShadow additionalClass="mt-5 holidays-con holidays-main-con">
                <BoxTitle title={t("Employerofthemonth")} />
                <DynamicTable
                    loading={loading}
                    dataSource={employies?.data?.data}
                    columns={cols}
                    pagination={false}
                />
            </BoxWithShadow>
            <ModalComponent show={show} handleClose={handleClose} arrayOfModals={modals} />
        </>
    )
}

export default EmployerOfTheMonthBox