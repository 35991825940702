import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { TableContext } from "../../../Context/TableContext"
import useQueryGet from "../../../Custom Hooks/useQueryGet"
import LeavesSuggestionService from "../../../Services/LeavesSuggestionService"

const ShowLeavesSuggestionModal = () => {
    const { t } = useTranslation()
    const { recordId } = useContext(TableContext)
    const [eventData] = useQueryGet(() => LeavesSuggestionService.show(recordId), ["get-single-leaves-suggestion", recordId])
    return (
        <>
            <div className="flex-start-center-row mb-3">
                <label className="mb-0 mx-2">{t("name")} : </label>
                <p>{eventData?.data?.data?.user?.name}</p>
            </div>
            <div className="flex-start-center-row mb-3">
                <label className="mb-0 mx-2">{t("direct_manager")} : </label>
                <p>{eventData?.data?.data?.approved_by_direct_manager == 0 ? t("unpproved") : t("approved")}</p>
            </div>
            <div className="flex-start-center-row mb-3">
                <label className="mb-0 mx-2">{t("general_manager")} : </label>
                <p>{eventData?.data?.data?.approved_by_general_manager == 0 ? t("unpproved") : t("approved")}</p>
            </div>
            <div className="flex-start-center-row mb-3">
                <label className="mb-0 mx-2">{t("hr")} : </label>
                <p>{eventData?.data?.data?.approved_by_hr == 0 ? t("unpproved") : t("approved")}</p>
            </div>
            <div className="flex-start-center-row mb-3">
                <label className="mb-0 mx-2"> {t("start")} : </label>
                <p>{eventData?.data?.data?.start_date}</p>
            </div>
            <div className="flex-start-center-row mb-3">
                <label className="mb-0 mx-2"> {t("end")} : </label>
                <p>{eventData?.data?.data?.end_date}</p>
            </div>
        </>
    )
}

export default ShowLeavesSuggestionModal