import { InputsArrayTypes } from "../../../../Models/InputsArrayTypes";

const InputsArray: InputsArrayTypes[] = [
    {
        id: 1,
        control: "date-picker",
        label: "start",
        name: "start",
        dateFormat: "YYYY-MM-DD"
    },
    {
        id: 2,
        control: "date-picker",
        label: "end",
        name: "end",
        dateFormat: "YYYY-MM-DD"
    },
    {
        id: 3,
        control: "input",
        as: "",
        label: "Title",
        name: "title",
    },
    {
        id: 4,
        control: "input",
        label: "Body",
        as: "textarea",
        name: "body"
    }
]
export default InputsArray