import { FC } from 'react'
import { BoxTitle, BoxWithShadow, DynamicTable } from '../../../Components'
import { useTranslation } from 'react-i18next'
import { SalaryTableCols } from '../../../Assets/Data/Dashboard/ProfileMangement/Salary'
type SalaryTableProps = {
    payrolls: any,
    loading: boolean
}
const SalaryTable: FC<SalaryTableProps> = ({ payrolls, loading }) => {
    const { t } = useTranslation()
    return (
        <BoxWithShadow additionalClass="mb-3 users">
            <div className="flex-between-start">
                <BoxTitle title={t("Salary")} />
            </div>
            <DynamicTable
                loading={loading}
                dataSource={payrolls}
                columns={SalaryTableCols(t).cols}
                pagination={false}
            />
        </BoxWithShadow>
    )
}

export default SalaryTable