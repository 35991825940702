import { InputsArrayTypes } from "../../../../../Models/InputsArrayTypes";

const DeductionInputs: InputsArrayTypes[] = [
    {
        id: 1,
        control: "date-picker",
        label: "date",
        name: "date",
        dateFormat: "YYYY-MM-DD"
    },
    {
        id: 2,
        control: "input",
        as: "",
        label: "amount",
        name: "amount",
    },
    {
        id: 3,
        control: "input",
        as: "textarea",
        label: "Description",
        name: "description"
    },
]
export default DeductionInputs