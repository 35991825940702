import "./Courses.css"
import { BoxTitle, MainBtn, ModalComponent } from "../../../../Components";
import { ToastContainer } from "react-toastify";
import CoursesService from "../../../../Services/CoursesService";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useModals from "../../../../Custom Hooks/useModals";
import { CoursesModals } from "../../../../Assets/Data/Dashboard/Courses";
import { CourseBox } from "../../../../Components/ProfileMangement/Courses";
import CheckIcons from "../../../../Helpers/CheckIcons";
import useQueryGet from "../../../../Custom Hooks/useQueryGet";

const Courses = () => {
    const params = useParams()
    const [data] = useQueryGet(() => CoursesService.get(params.id), ["get-courses", params.id])
    const { t } = useTranslation()
    const { show, handleShow, handleClose } = useModals({
        addCourse: false,
        editCourse: false,
        Delete: false
    })
    const { modals } = CoursesModals(handleClose)
    const courses = data?.data?.data?.courses_libraries?.map((item: any) => {
        let icon = CheckIcons(item.icon)
        return (
            <CourseBox
                course_name={item.course_name}
                handleShow={handleShow}
                icon={icon}
                id={item.id} />
        )
    }
    )
    return (
        <>
            <div className="file-mangement-con">
                <div className="flex-between mb-5">
                    <BoxTitle title={t("CourseManagement")} />
                    <MainBtn title={t("add")} onClick={() => handleShow("addCourse")} />
                </div>
                <div className="row row-cols-3">
                    {courses}
                </div>
            </div>
            <ModalComponent arrayOfModals={modals} show={show} handleClose={handleClose} />
            <ToastContainer />
        </>
    )
}

export default Courses