import AddUserSchema from "../../Utils/Validations/Users/AddUserSchema";
import { useTranslation } from "react-i18next";
import { FormikContainer } from "../Forms";
import { InputsArray } from "../../Assets/Data/Dashboard/Users";
import { FormikHelpers, FormikValues } from "formik";
import { FC } from "react";
import useQueryPost from "../../Custom Hooks/useQueryPost";
import UserService from "../../Services/UserService";
const initialValues = {
    image: "",
    type: "",
    email: "",
    name: "",
    date_of_birth: "",
    mobile: "",
    nationality: "",
    home_country: "",
    address_home: "",
    employer: "",
    official_date_of_employment: "",
    password: "",
    role: [],
    blood_type: "",
    work_description: "",
    personal_id: "",
    position: "",
    department_id: 1,
    university_certificate: "",
    base_salary: "",
    labor_card_number: Math.random()
}
type AddModalProps = {
    roles: any,
    mangerOptions: any[],
    handleClose: () => void
}
const AddModal: FC<AddModalProps> = ({ roles, mangerOptions, handleClose }) => {
    const { t } = useTranslation()
    const [handleSubmit,] = useQueryPost(UserService.addUser, ["add-new-user"], {
        success: t("added-succssefully")
    }, ["get-all-users"], () => {
        handleClose()
    })
    const onSubmit = (values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        handleSubmit(values)
            .then((_) => {
                onSubmitProps.setSubmitting(false)
            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }
    return (
        <FormikContainer
            onSubmit={onSubmit}
            initialValues={initialValues}
            schema={AddUserSchema}
            className=""
            btnText={t("add")}
            arrayOfInputs={InputsArray(roles, mangerOptions).inputs}
            OptionalConfig={{
                isNeedCheck: true,
                checkName: "type",
                checkValue: "On-site"
            }}
            optionalFileds={["address_uae",
                "emergency_contact_person",
                "emergency_contact_mobile",
                "emergency_contact_relation",
                "passport_number",
                "passport_expiry_date",
                "passport_place_of_issue",
                "visa_type",
                "visa_expiry_date",
                "visa_place_of_issue",
                "visa_id_number",
                "labor_card_issue_date",
                "labor_card_expiry_date",
                "labor_card_number",
                "bank_name",
                "account_holder_name",
                "account_number",
                "iban",
                "swift_code"]}
        />
    )
}

export default AddModal