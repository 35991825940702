import { InputsArrayTypes } from "../../../../../Models/InputsArrayTypes";

const SalaryIncreasesInputs: InputsArrayTypes[] = [
    {
        id: 1,
        control: "date-picker",
        label: "date",
        name: "date",
        dateFormat: "YYYY-MM-DD"
    },
    {
        id: 2,
        control: "input",
        as: "",
        label: "amount",
        name: "amount",
    },
]
export default SalaryIncreasesInputs