import { InputsArrayTypes } from "../../../../Models/InputsArrayTypes";

const InputsArray: InputsArrayTypes[] = [
    {
        id: 1,
        as: "",
        name: "name",
        label: "name",
        control: "input",
    },
]
export default InputsArray