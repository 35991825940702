import { ToastContainer } from "react-toastify"
import { BoxTitle, BoxWithShadow, DynamicTable, ModalComponent } from "../../../Components"
import "./LeavesSuggestion.css"
import { useTranslation } from "react-i18next"
import LeavesSuggestionService from "../../../Services/LeavesSuggestionService"
import useModals from "../../../Custom Hooks/useModals"
import { LeavesSuggestionsModals, LeavesSuggestionCols } from "../../../Assets/Data/Dashboard/LeavesSuggestion"
import useQueryGet from "../../../Custom Hooks/useQueryGet"

const LeavesSuggestion = () => {
    const { t } = useTranslation()
    const [dataSource, , , loading] = useQueryGet(LeavesSuggestionService.getAll, ["get-all-leaves-suggestion"])
    const { show, handleShow, handleClose } = useModals({
        showLeavesSuggestion: false,
        editLeavesSuggestion: false,
        Delete: false
    })
    const { modals } = LeavesSuggestionsModals(handleClose)
    return (
        <>
            <BoxWithShadow additionalClass={`container-fluid users mb-4`}>
                <div className="flex-between">
                    <BoxTitle title={t("Leaves-Suggestion")} />
                </div>
                <DynamicTable
                    loading={loading}
                    dataSource={dataSource?.data?.data}
                    columns={LeavesSuggestionCols(t, handleShow).columns}
                    pagination={false}
                />
                <ModalComponent arrayOfModals={modals} handleClose={handleClose} show={show} />
            </BoxWithShadow>
            <ToastContainer />
        </>
    )
}

export default LeavesSuggestion