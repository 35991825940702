import React, { Fragment } from 'react';
import { Modal, ModalHeader, ModalTitle, ModalBody, ModalFooter, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import "./ModalComponent.css"
interface ModalProps {
    arrayOfModals: any;
    show: any;
    handleClose: (name: string) => void
}

const ModalComponent: React.FC<ModalProps> = ({ show, handleClose, arrayOfModals }) => {
    const { i18n } = useTranslation()
    const modals = arrayOfModals.map((modal: any) => {
        if (show[modal.name] == true) {
            if (modal.name == "notification") {
                return (
                    <Modal
                        key={modal.id}
                        show={show[modal.name]}
                        onHide={() => handleClose(modal.name)}
                        id="EditUser"
                        className={`modal users-modal ${modal.name === "notification" && "notification-modal"} ${i18n.language == "ar" && "ar"}`}
                        centered
                        fullscreen={true}
                    >
                        <div className="modal-header">
                            {modal.title}
                            <button type="button" className="btn-close" onClick={() => handleClose(modal.name)}></button>
                        </div>
                        <div className="modal-body">
                            {modal.children}
                        </div>
                    </Modal>
                )
            }
            else {
                return (
                    <Modal
                        key={modal.id}
                        show={show[modal.name]}
                        onHide={() => handleClose(modal.name)}
                        id="EditUser"
                        className="users-modal"
                        centered
                    >
                        <div className="modal-header">
                            {modal.title}
                            <button type="button" className="btn-close" onClick={() => handleClose(modal.name)}></button>
                        </div>
                        <div className="modal-body">
                            {modal.children}
                        </div>
                    </Modal>
                )
            }
        }
        else {
            return <Fragment key={modal.id}></Fragment>
        }

    }
    )
    return (
        <>
            {modals}
        </>
    );
};

export default ModalComponent;