import { useContext, useState } from 'react'
import { TableContext } from '../../../../Context/TableContext'
import { DeleteModal } from '../../../../Components'
import { useTranslation } from 'react-i18next'
import LeavesSuggestionService from '../../../../Services/LeavesSuggestionService'
import { EditModal, ShowModal } from '../../../../Components/LeavesSuggestion'

const LeavesSuggestionsModals = (handleClose: (name: string) => void) => {
    const { i18n, t } = useTranslation()
    const { recordId } = useContext(TableContext)
    const [data, setData] = useState<any>()
    const modals = [
        {
            id: 1,
            name: "showLeavesSuggestion",
            title: t("show"),
            children: <ShowModal />

        },
        {
            id: 2,
            name: "editLeavesSuggestion",
            title: t("edit"),
            children: <EditModal handleClose={handleClose} />
        },
        {
            id: 3,
            name: "Delete",
            title: t("Delete"),
            children: <DeleteModal
                btnText={t("Delete")}
                endPointConfig={{
                    invalidate: "get-all-leaves-suggestion",
                    queryKey: ["delete-leave-suggestion"],
                    endPoint: LeavesSuggestionService.delete
                }}
                handleClose={() => handleClose("Delete")} />
        }
    ]

    return { modals }
}

export default LeavesSuggestionsModals